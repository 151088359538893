import _ from 'lodash'
import { useContext, useMemo } from 'react'
import type { ApolloError } from '@apollo/client'
import { captureException } from '@sentry/browser'
import { AppDataContext } from '../../../context/AppDataContext'
import {
  Aggregation,
  Category,
  FilterInput,
  Period,
} from '../../../graphql/generated'
import { useEmissionData } from '../../../hooks/useEmissionData'
import { notEmpty } from '../../../utils/array'

const alpha_shift = (col: string, idx: number, tot: number): string => {
  if (!(col && col[0] === '#')) return ''

  let target: number
  if (idx === Math.floor(tot / 2)) return col

  if (idx < Math.floor(tot / 2)) {
    target = 255
  } else {
    target = 0
  }

  const ratio = 1 - 0.2 * Math.abs(idx - Math.floor(tot / 2))

  let r = parseInt(col.substring(1, 3), 16)
  let g = parseInt(col.substring(3, 5), 16)
  let b = parseInt(col.substring(5, 7), 16)

  r = Math.round(ratio * r + (1 - ratio) * target)
  g = Math.round(ratio * g + (1 - ratio) * target)
  b = Math.round(ratio * b + (1 - ratio) * target)

  const fix_hex = (x: number): string =>
    Math.max(x, 0).toString(16).padStart(2, '0')

  return `#${fix_hex(r)}${fix_hex(g)}${fix_hex(b)}`
}

export type EmissionData = {
  category: Category
  totalCO2e: number
  percent: string
  aggregated?: EmissionData[]
  period: string
  sniModifiedByCustomerRule?: boolean
  intensityModifiedByCustomerRule?: boolean
}

export type EmissionsData = {
  totalCO2e: number
  emissions: EmissionData[]
  history?: EmissionsData
}

export type CategroyPieData = {
  data: EmissionsData | undefined
  loading: boolean
  error: ApolloError | undefined
}

// Take a sorted array of emissions as argument
// Retruns the list of emissions to be displayed in the pie chart
// The goal is to make the graph easy to read by having a "lagom" ammount of emissions and not show too small values
// Do not display more than 7 emissions
// If there are more than 7 emissions, do not display the emissions that represent less than 2%
// If there are less than 7 emissions, do not display emission that represent less than 1%
const getToDisplay = (emissions: EmissionData[], totalCO2e: number) => {
  let toDisplay = emissions
  if (emissions.length >= 7) {
    // We expect the emissions to be sorted so we can do the slice before
    // the filter, to gain a little time
    toDisplay = emissions
      .slice(0, 7)
      .filter(
        (x) => x.totalCO2e > 0.02 * totalCO2e && x.category.name !== 'Övrigt',
      )
  } else {
    toDisplay = toDisplay.filter(
      (x) => x.totalCO2e > 0.01 * totalCO2e && x.category.name !== 'Övrigt',
    )
  }
  return toDisplay.map((e) => {
    return { ...e, percent: ((e.totalCO2e / totalCO2e) * 100).toFixed(0) }
  })
}

export const selectEmissions = (
  allEmissions: EmissionData[],
  otherCat: Category,
): EmissionData[] => {
  const totalCO2e = _.sumBy(allEmissions, (x) => x.totalCO2e)
  const emissions = getToDisplay(allEmissions, totalCO2e)

  // If there are more emission than the displayed one, aggregate the remaining in an "other" cathegory
  if (emissions.length < allEmissions.length) {
    // As allEmissions is sorted taking
    // all the emission from allEmissions with an index higher than the last one in emissions
    // should give us all the remaining emissions
    const remainderEmissions = allEmissions.slice(emissions.length)
    const remainderTotalCO2e =
      totalCO2e - _.sumBy(emissions, (x) => x.totalCO2e)
    const remainderPercent = (remainderTotalCO2e / totalCO2e) * 100
    emissions.push({
      category: otherCat,
      totalCO2e: remainderTotalCO2e,
      percent:
        Math.round(remainderPercent) >= 1 ? remainderPercent.toFixed(0) : '<1',
      aggregated: remainderEmissions,
      period: emissions[0].period,
    })
  }
  return emissions
}

// Generate sub category colors
export const applyColor = (
  emissions: EmissionData[],
  categoryFilter: Category,
): EmissionData[] => {
  const base_color = categoryFilter.color
  const n = emissions.length
  return emissions.map((row, idx) => ({
    ...row,
    category: { ...row.category, color: alpha_shift(base_color, idx, n) },
  }))
}

export const useCategoryPieData = (
  filter: FilterInput,
  period: Period,
  categoryFilter?: Category,
): CategroyPieData => {
  const { categoryMap } = useContext(AppDataContext)

  const aggregations = [
    categoryFilter ? Aggregation.SubCategory : Aggregation.PrimaryCategory,
  ]

  const {
    data: emissionData,
    loading,
    error,
  } = useEmissionData({
    filter,
    aggregations,
    period,
  })

  return useMemo(() => {
    const totalCO2e = _.sumBy(emissionData, (x) => x.totalCO2e)

    const allEmissions = emissionData
      .map<EmissionData | undefined>((emission) => {
        const categoryId = emission.aggregationKeys[0]
        const foundCategory = categoryMap[categoryId]
        if (!foundCategory) {
          captureException(
            `allEmissions contains emission ids that are not in the categoryMap ${categoryId}`,
          )
          return undefined
        }
        return {
          category: foundCategory,
          totalCO2e: emission.totalCO2e,
          percent: ((emission.totalCO2e / totalCO2e) * 100).toFixed(0),
          period: emission.period,
        }
      })
      .filter(notEmpty)
      .sort((a, b) => b.totalCO2e - a.totalCO2e)

    const otherCategory = categoryMap['4'] as Category

    let emissions = allEmissions

    if (categoryFilter) {
      emissions = selectEmissions(allEmissions, otherCategory)
      emissions = applyColor(emissions, categoryFilter)
    }

    return {
      data: emissions.length
        ? {
            totalCO2e,
            emissions,
          }
        : undefined,
      loading,
      error,
    }
  }, [categoryFilter, categoryMap, emissionData, error, loading])
}

export function getEmission(
  id: number,
  emissions: EmissionData[],
): EmissionData | undefined {
  return emissions.find((emission) => emission.category.id === id)
}
