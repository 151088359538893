import React, { useContext, useEffect, useState } from 'react'
import { captureException } from '@sentry/browser'
import type Svalna from '../../../@types'
import { setToken } from '../../../utils/auth'
import {
  ErrorType,
  useLoginWithFortnoxMutation,
} from '../../../graphql/generated'
import { UserContext } from '../../../context/UserContext'

export enum FortnoxErrorType {
  MissingLicense,
  OrgNotRegistered,
  NoError,
  UserNotRegistered,
  NotApproved,
  RegistrationCodeExpired,
  RegistrationCodeNotFound, // no entry in the db with this code in the db
  RegistrationCodeMissing, // no code in the request sent by the user
}

interface IUserContext {
  setCode: (code: string) => void
  loading: boolean
  setLoading: (loading: boolean) => void
  fortnoxErrorType: FortnoxErrorType
  setFortnoxErrorType: (errorType: FortnoxErrorType) => void
  error: string
  setError: (error: string) => void
  setOrgRegistrationCode: (registrationCode: string | null) => void
  orgRegistrationCode: string | null
  setUserRegistrationCode: (registrationCode: string | null) => void
  userRegistrationCode: string | null
}

const initialState: IUserContext = {
  setCode: () => {},
  loading: true,
  setLoading: () => {},
  fortnoxErrorType: FortnoxErrorType.NoError,
  setFortnoxErrorType: () => {},
  error: '',
  setError: () => {},
  setOrgRegistrationCode: () => {},
  orgRegistrationCode: '',
  setUserRegistrationCode: () => {},
  userRegistrationCode: '',
}

export const FortnoxLoginContext =
  React.createContext<IUserContext>(initialState)

const { Provider } = FortnoxLoginContext

export function FortnoxLoginProvider({
  children,
}: Svalna.PropWithChildren): React.JSX.Element {
  const { setLoggedIn } = useContext(UserContext)
  const [loginWithFortnox] = useLoginWithFortnoxMutation({})
  const [code, setCode] = useState('')
  const [orgRegistrationCode, setOrgRegistrationCode] = useState<string | null>(
    null,
  )
  const [userRegistrationCode, setUserRegistrationCode] = useState<
    string | null
  >(null)
  const [loading, setLoading] = useState(true)
  const [fortnoxErrorType, setFortnoxErrorType] = useState(
    FortnoxErrorType.NoError,
  )
  const [error, setError] = useState('')

  useEffect(() => {
    if (code) {
      loginWithFortnox({
        variables: {
          code: code,
          redirectUri: window.location.origin + window.location.pathname,
          registrationCode: userRegistrationCode ?? orgRegistrationCode,
        },
      })
        .then((res) => {
          if (res.data?.loginWithFortnox.errors) {
            captureException(res.data.loginWithFortnox.errors)
            res.data.loginWithFortnox.errors.forEach((e) => {
              switch (e.errorType) {
                case ErrorType.NoOrg:
                  setFortnoxErrorType(FortnoxErrorType.OrgNotRegistered)
                  break
                case ErrorType.NotApproved:
                  setFortnoxErrorType(FortnoxErrorType.NotApproved)
                  break
                case ErrorType.UserNotRegistered:
                  setFortnoxErrorType(FortnoxErrorType.UserNotRegistered)
                  break
                case ErrorType.RegistrationCodeExpired:
                  setFortnoxErrorType(FortnoxErrorType.RegistrationCodeExpired)
                  break
                case ErrorType.RegistrationCodeNotFound:
                  setFortnoxErrorType(FortnoxErrorType.RegistrationCodeNotFound)
                  break
                case ErrorType.RegistrationCodeMissing:
                  setFortnoxErrorType(FortnoxErrorType.RegistrationCodeMissing)
                  break

                default:
                  setError(e.message)
              }
            })
            setLoading(false)
            return
          }

          if (res.data?.loginWithFortnox.authentication) {
            const { token, expiresAt, refreshToken } =
              res.data.loginWithFortnox.authentication
            setToken(token, refreshToken, expiresAt)
            setLoggedIn(true)
          }
        })
        .catch((er) => {
          setError(er.message)
          setLoading(false)
        })
    }
  }, [
    code,
    loginWithFortnox,
    setLoggedIn,
    userRegistrationCode,
    orgRegistrationCode,
  ])

  return (
    <Provider
      value={{
        setCode,
        fortnoxErrorType,
        loading,
        setFortnoxErrorType,
        setLoading,
        error,
        setError,
        setOrgRegistrationCode,
        orgRegistrationCode,
        setUserRegistrationCode,
        userRegistrationCode,
      }}
    >
      {children}
    </Provider>
  )
}
