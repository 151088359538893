import React, { useMemo } from 'react'
import { Text, Svg, G } from '@react-pdf/renderer'
import { Slice, VictoryPie } from 'victory'
import { CategroyPieData } from '../../charts/category-pie/useCategoryPieData'
import { getNumberFormatter } from '../../../utils/adaptiveNumberFormat'
import { PathBridge } from './reactPdfVictoryUtils'
import {
  fontSize14,
  fontSize32,
  fontSize8,
  ghgpPieHeight,
  ghgpPieWidth,
  pdfFontFamily,
} from './pdfStyles'

// The pie radius and text placement relative the pie radius/centre:
const centreX = ghgpPieWidth / 2
const centreY = ghgpPieHeight / 2
const outerRadius = ghgpPieWidth / 2
const innerRadius = outerRadius - 23
// Y positions for the texts displayed in the centre of the pie:
const sumTextY = centreY - 16 - 4
const unitTextY = centreY + 28 - 4

type Props = { pieData?: CategroyPieData }

/**
 * A pie chart over emissions by ghgp scope made with components
 * which can be rendered by `@react-pdf/renderer`.
 *
 * The given emissions should all have the same period.
 *
 * The "categories" are expected to be ghgp scopes;
 * The category id should be the scope, the name should
 * be the name of the scope, and so on.
 */
export function PieForPdf({ pieData }: Props): React.JSX.Element {
  const { emissions, dataPoints, total, totalFormatter } = useMemo(() => {
    const es = pieData?.data?.emissions ?? []
    const tot = es.reduce((sum, p) => sum + p.totalCO2e, 0)
    const totFmt = getNumberFormatter([tot], { hideUnit: true })
    return {
      emissions: es,
      dataPoints: es.map((e) => ({
        x: e.category.id.toFixed(0),
        y: e.totalCO2e,
      })),
      total: tot,
      totalFormatter: totFmt,
    }
  }, [pieData])

  return (
    <Svg viewBox={`0 0 ${ghgpPieWidth} ${ghgpPieHeight}`}>
      <VictoryPie
        padding={0}
        colorScale={emissions.map((e) => e.category.color)}
        style={{
          parent: {
            fontFamily: pdfFontFamily,
          },
          data: {
            fontFamily: pdfFontFamily,
            stroke: 'none',
          },
        }}
        animate={false}
        labels={[]}
        width={ghgpPieWidth}
        height={ghgpPieHeight}
        radius={outerRadius}
        innerRadius={innerRadius}
        standalone={false}
        data={dataPoints}
        containerComponent={<G />}
        groupComponent={<G />}
        dataComponent={
          <Slice
            containerComponent={<G />}
            groupComponent={<G />}
            pathComponent={<PathBridge />}
          />
        }
      />
      <Text
        x={centreX}
        y={sumTextY}
        // middle: X-coord is centre of text
        textAnchor='middle'
        // hanging: Y-coord is top of the text
        dominantBaseline='hanging'
        style={{
          fontSize: fontSize32,
        }}
      >
        {totalFormatter.format(total)}
      </Text>
      {/*
        Text with units, e.g. "kton CO2e",
        since @react-pdf/renderer cannot handle a font with subscript (the 2 in CO2e)
        this has to be positioned manually, and the measurement will need to be adjusted
        to fit kg/ton/kton etc.
      */}
      <UnitText
        pieCentreX={centreX}
        textY={unitTextY}
        unit={totalFormatter.unit}
      />
    </Svg>
  )
}

function UnitText({
  unit,
  pieCentreX,
  textY,
}: {
  unit: 'kg' | 'ton' | 'kton' | 'Mton'
  pieCentreX: number
  textY: number
}): React.JSX.Element {
  // HACK: Terrible hack, but it's the best I could come up with:
  // In order to have the text "CO2e" display the "2" as subscript,
  // it is positioned manually depending on what emission unit is displayed.
  // This is the method chosed because @react-pdf/renderer:
  // - does not display an actual subscript font correctly
  // - has no style options for subscript
  // and creating a stand-alone text SVG is still cumbersome, because it
  // would have the same restrictions.
  // The unit is dependent on the font family and size,
  // this is written for a size 14 Roboto (regular).
  let subOffset = 0
  switch (unit) {
    case 'kg':
      subOffset = 14.5
      break
    case 'ton':
      subOffset = 17.25
      break
    case 'kton':
      subOffset = 20.5
      break
    case 'Mton':
      subOffset = 23
      break
    default:
      //The cases should be exhaustive, this should never happen.
      subOffset = 20.5
  }

  // IMPORTANT: The above constants depend on both the font size and
  //            family used here; if they are changed, the values must
  //            be updated for the text to align properly.
  //            At the time of writing [2024-11-26], the font is:
  //            Roboto (regular), size 14  ("CO e")
  //            Roboto (regular), size  8  ("2" subscript)
  return (
    <G style={{ fontFamily: pdfFontFamily }}>
      {/* Note the space in "CO e", this is to allow the "2" to fit. */}
      <Text
        x={pieCentreX}
        y={textY}
        textAnchor='middle'
        style={{ fontSize: fontSize14 }}
      >
        {`${unit} CO e`}
      </Text>
      <Text
        x={pieCentreX + subOffset} // manual estimate of space coord in "CO e"
        y={textY + 5} // "subscript" by drawing further down.
        style={{ fontSize: fontSize8 }} // "subscript" is smaller than sibling text
        textAnchor='middle'
      >
        2
      </Text>
    </G>
  )
}
