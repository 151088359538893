import React, { useContext, useEffect, useId, useState } from 'react'
import styled from 'styled-components'
import { AccordionDetails, AccordionSummary } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { usePDF } from '@react-pdf/renderer'
import { captureMessage } from '@sentry/browser'
import { Category, FilterInput } from '../../graphql/generated'
import { useFilterInput } from '../../hooks/useFilterInput'
import { colors, spacing } from '../../theme'
import { unitDescription } from '../../utils/adaptiveNumberFormat'
import { testHasData } from '../../utils/testHasData'
import CardTemplate from '../CardTemplate'
import { CategoryPie } from '../charts/category-pie/CategoryPie'
import { CategoryLabels } from '../charts/category-pie/CategoryPieLabels'
import { CategroyPieData } from '../charts/category-pie/useCategoryPieData'
import { FilterContext } from '../../context/FilterContext'
import { Body1 } from '../Typography'
import { AppDataContext } from '../../context/AppDataContext'
import { CategoryBreadCrumbs } from '../charts/category-pie/CategoryBreadCrumbs'
import { CategoryInfo } from '../charts/category-pie/CategoryInfo'
import { Flex, FlexingBoxContent } from '../Flex'
import { ButtonLightBorder } from '../Buttons'
import { GhgpDoc } from './pdf/GhgpDoc'
import { useGhgpData } from './useGhgpData'
import { SnackAlert } from '../SnackAlert'

const PieContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
`

const Accordion = styled(MuiAccordion)`
  box-shadow: none;
  &::before {
    background-color: ${colors.white};
  }
`

/**
 * Card controls for GHGp pie.
 */
function Controls({
  href,
  pdfName,
  disabled,
}: {
  /** URL to blob PDF */
  href?: string
  pdfName?: string
  disabled?: boolean
}): React.JSX.Element {
  const labelId = useId()
  return (
    <div style={{ marginLeft: 'auto' }}>
      {/* Hidden label for screen readers, clarifying what the button does. */}
      <span id={labelId} style={{ display: 'none', visibility: 'hidden' }}>
        Exportera PDF med utsläpp fördelade över GHG-p scopes.
      </span>
      <ButtonLightBorder<'a'>
        disabled={disabled || !href}
        aria-describedby={labelId}
        endIcon={<OpenInNewIcon />}
        // Setting href on a MUI button causes it to use an <a> element.
        href={href}
        download={`${pdfName}.pdf`}
        target='_blank' // May open in new tab, depending on browser.
        rel='noreferrer'
      >
        Skapa intyg
      </ButtonLightBorder>
    </div>
  )
}

/**
 * Note: [2024-11] This component is largely a copy of `CategoryPieCard`,
 * since the pie should have the same functionality of pie/table and drilldown etc.
 *
 * TODO? Refactcor common code/components used by this and CategoryPieCard.
 */
export function GhgpPieCard(): React.JSX.Element {
  const { organisation } = useContext(AppDataContext)
  const { defaultDatePeriod } = useContext(FilterContext)
  const [useAnnualWorkForce, setUseAnnualWorkForce] = useState(false)
  const [selectedDatePeriod, setDatePeriod] = useState(defaultDatePeriod)
  const [errorMsg, setErrorMsg] = useState<string>('')
  // To re-use the category pie and table, present ghgp scope as a "category".
  const [ghgpScope, setGhgpScope] = useState<Category>()
  const filter = useFilterInput(
    undefined, // no category.
    selectedDatePeriod,
    useAnnualWorkForce,
  )
  const pieData = useGhgpData({
    filter,
    period: selectedDatePeriod.period,
    // TODO: If we add sub-scopes, include list of scopes here,
    //       in the same way the sub-categories work in the category pie data hook.
  })
  const [doc, setDoc] = usePDF()

  // Name of the PDF, should be e.g. "Scope1-3_2020" or "Scope1-3_2024-jan-maj".
  // `partialPeriodText` is either undefined or e.g. "jan - maj",
  // convert it to "-jan-maj" or empty string.
  const namePostfix =
    selectedDatePeriod.partialPeriodText?.replace(/ /g, '').replace(/^/, '-') ??
    ''
  const pdfName = `Scope1-3_${selectedDatePeriod.name}${namePostfix}`

  useEffect(() => {
    if (doc.error) {
      captureMessage(doc.error)
      setErrorMsg('Kunde inte skapa PDF')
    }
  }, [doc.error])

  useEffect(() => {
    // This may overwrite the date set by a user, but this would only happen
    // when loading data from fortnox for the first time in the year
    setDatePeriod(defaultDatePeriod)
  }, [defaultDatePeriod])

  useEffect(() => {
    if (!pieData.loading && pieData.data?.emissions.length) {
      // This blocks the render thread, but our PDF is small,
      // and this should only take on the order of milliseconds.
      setDoc(
        <GhgpDoc
          pieData={pieData}
          organisation={organisation}
          selectedPeriod={selectedDatePeriod}
          title={pdfName}
          author='Svalna AB'
          creator='Svalna AB'
        />,
      )
    }
  }, [organisation, pieData, pdfName, pieData.data, selectedDatePeriod, setDoc])

  return (
    <>
      <SnackAlert
        open={!!errorMsg.length}
        severity='error'
        onClose={() => setErrorMsg('')}
      >
        {errorMsg}
      </SnackAlert>
      <CardTemplate
        titleData={{
          title: 'Utsläpp fördelat på scopes',
          subtitle: unitDescription(filter),
        }}
        useAnnualWorkForce={useAnnualWorkForce}
        setUseAnnualWorkForce={setUseAnnualWorkForce}
        selectedDatePeriod={selectedDatePeriod}
        setDatePeriod={setDatePeriod}
        loading={pieData.loading}
        error={pieData.error}
        hasData={testHasData(pieData.data)}
        loadingPlaceholderHeight={404}
        tooltip={
          <Body1>
            Visar utsläpp fördelat på GHG-p scope. Klicka för att se information
            om leverantörer och bokföringskonton.
          </Body1>
        }
        ControlsEnd={
          <Controls
            disabled={doc.loading || !!doc.error || !doc.url}
            href={doc.url ?? undefined}
            pdfName={pdfName}
          />
        }
      >
        <Contents
          pieData={pieData}
          filter={filter}
          setGhgpScope={setGhgpScope}
          ghgpScope={ghgpScope}
        />
      </CardTemplate>
    </>
  )
}

type ContentProps = {
  pieData: CategroyPieData
  filter: FilterInput
  setGhgpScope: React.Dispatch<React.SetStateAction<Category | undefined>>
  ghgpScope: Category | undefined
}

function Contents({ pieData, filter, setGhgpScope, ghgpScope }: ContentProps) {
  const [showCategoryInfo, setShowCategoryInfo] = useState(false)
  const [selectedGhgpScope, setSelectedGhgpScope] = useState<
    Category | undefined
  >()
  const [emissions, setEmissions] = useState(pieData.data)

  useEffect(() => {
    setEmissions(pieData.data)
  }, [pieData.data])

  const canGoDeeper = () => false
  const dig = () => {}

  const onCategoryClick = (cat: Category | undefined) => {
    if (cat) {
      setSelectedGhgpScope(cat)
      setShowCategoryInfo(true)
    }
  }

  return (
    <>
      <FlexingBoxContent>
        <CategoryBreadCrumbs
          ghgp
          category={ghgpScope}
          setCategory={setGhgpScope}
          setSelectedCategory={setSelectedGhgpScope}
          emissions={emissions}
          setEmissions={setEmissions}
          pieData={pieData}
          selectedCategory={selectedGhgpScope}
        />
        <Flex row>
          <Flex column style={{ width: '50%' }}>
            <PieContainer column justifyCenter>
              <Flex
                column
                stretchWidth
                itemsCenter
                style={{ gap: spacing.large }}
              >
                <CategoryPie
                  data={emissions}
                  onCategoryClick={setSelectedGhgpScope}
                  selectedCategory={selectedGhgpScope}
                  canGoDeeper={canGoDeeper}
                  dig={dig}
                />
              </Flex>
            </PieContainer>
          </Flex>
          <CategoryLabels
            emissions={emissions?.emissions ?? []}
            onCategoryClick={onCategoryClick}
            selectedCategory={selectedGhgpScope}
            filter={filter}
            canGoDeeper={canGoDeeper}
            dig={dig}
            ghgp
          />
        </Flex>
      </FlexingBoxContent>
      <Accordion
        expanded={!!showCategoryInfo}
        onChange={() => setShowCategoryInfo((value) => !value)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='advance'
          id='advance-header'
          style={{ flexDirection: 'row-reverse' }}
        >
          <Body1>Leverantörer och Bokföringskonton</Body1>
        </AccordionSummary>
        <AccordionDetails>
          <CategoryInfo
            ghgp
            category={ghgpScope}
            emissions={emissions}
            filter={{ ...filter, ghgpScope: selectedGhgpScope?.id }}
            selectedCategory={selectedGhgpScope}
            showCategoryInfo
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
