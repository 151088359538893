import React, { ChangeEvent, useEffect } from 'react'
import { captureException } from '@sentry/browser'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
} from '@mui/x-data-grid'
import SaveIcon from '@mui/icons-material/Save'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import RsvpIcon from '@mui/icons-material/Rsvp'
import {
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from '@mui/material'
import { Flex, FlexingBox, HeadRow } from '../../components/Flex'
import { Body1 } from '../../components/Typography'
import {
  MeDocument,
  OrgType,
  Role,
  UsersDocument,
  useChangeRestrictionModeMutation,
  useDeleteUserMutation,
  useMeQuery,
  useUpdateRegistrationCodeMutation,
  useUpdateRoleMutation,
  useUsersQuery,
} from '../../graphql/generated'
import { TitleWithSub } from '../../components/TitleWithSub'
import { AddUserForm } from './AddUserForm'
import { ButtonGreen, ButtonLightBorder } from '../../components/Buttons'
import { FullWidthBox } from '../../components/Box'
import { TooltipInfo } from '../../components/InfoIcon'
import { LinkSentPopup } from './sysadminSections/LinkSentPopup'
import { spacing } from '../../theme'
import { SnackAlert } from '../../components/SnackAlert'

type Row = {
  id: string
  firstName: string
  lastName: string
  username: string
  organisation: {
    name: string
    id: string
  }
  role: Role
  invited: boolean
}

interface Props {
  sysAdmin?: boolean
}

export function UserManagement({ sysAdmin }: Props): React.JSX.Element {
  const { data } = useMeQuery()
  const user = data?.me
  const { data: usersData } = useUsersQuery({
    variables: { allOrgs: sysAdmin },
  })
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  )
  const [rows, setRows] = React.useState<GridRowsProp<Row>>([])
  const [updateRole] = useUpdateRoleMutation()
  const [error, setError] = React.useState('')
  const [userToDelete, setUserToDelete] = React.useState<Row | undefined>(
    undefined,
  )
  const [doDeleteUser] = useDeleteUserMutation({
    refetchQueries: [{ query: UsersDocument }],
  })
  const [changeRestrictionMode] = useChangeRestrictionModeMutation({
    refetchQueries: [{ query: MeDocument }],
  })

  const [updateRegistrationCode] = useUpdateRegistrationCodeMutation()
  const [registrationEmail, setRegistrationEmail] = React.useState('')

  useEffect(() => {
    setRows(usersData?.users ?? [])
  }, [usersData, setRows])

  const handleEditClick = (id: GridRowId) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id: GridRowId) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = (id: GridRowId) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    setUserToDelete(rows.find((r) => r.id === id))
  }

  const handleUpdateRegistrationCode = (id: GridRowId) => async () => {
    try {
      const toUpdate = rows.find((r) => r.id === id)
      if (!toUpdate?.id) {
        // should never happen
        throw new Error('did not find the user in the dataGrid')
      }
      const result = await updateRegistrationCode({
        variables: {
          id: toUpdate.id,
          organisationId:
            user?.role === Role.SysAdmin ? toUpdate.organisation.id : undefined,
        },
      })
      if (result.data) {
        setRegistrationEmail(result.data.updateRegistrationCode)
        return
      }
      captureException(result.errors)
      setError('Något gick fel')
    } catch (err) {
      captureException(err)
      setError('Något gick fel')
    }
  }

  const roleOptions = Object.entries(Role)
    .filter(([name, role]) => {
      if (user?.role === Role.SysAdmin) {
        return true
      }
      return role !== Role.SysAdmin
    })
    .map(([name, role]) => {
      return {
        value: role,
        label: name,
      }
    })

  const columns: GridColDef<Row>[] = [
    { field: 'firstName', headerName: 'Förnamn', flex: 1 },
    { field: 'lastName', headerName: 'Efternamn', flex: 1 },
    { field: 'username', headerName: 'Användarnamn', flex: 1 },
    {
      field: 'role',
      headerName: 'Roll',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: roleOptions,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Handlingar',
      width: 120,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={`save-${id}`}
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={() => handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={`cancel-${id}`}
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={() => handleCancelClick(id)}
              color='inherit'
            />,
          ]
        }

        const actions = [
          <GridActionsCellItem
            key={`edit-${id}`}
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={() => handleEditClick(id)}
            color='inherit'
          />,
          <GridActionsCellItem
            key={`edit-${id}`}
            icon={<DeleteIcon />}
            label='Delete'
            className='textPrimary'
            onClick={handleDeleteClick(id)}
            color='inherit'
            disabled={id === user?.id}
            data-testid={`delete-button-${id}`}
          />,
        ]

        if (row.invited) {
          actions.push(
            <GridActionsCellItem
              key={`rsvp-${id}`}
              icon={
                <Tooltip title='Uppdatera registreringslänk'>
                  <RsvpIcon />
                </Tooltip>
              }
              label='invite'
              className='textPrimary'
              onClick={handleUpdateRegistrationCode(id)}
              color='inherit'
              data-testid={`rsvp-button-${id}`}
            />,
          )
        }

        return actions
      },
    },
  ]

  if (sysAdmin) {
    columns.unshift({
      field: 'organisation',
      headerName: 'organisation',
      flex: 1,
      valueGetter: (value, row) => {
        return row.organisation.name
      },
    })
  }

  const processRowUpdate = async (newRow: GridRowModel<Row>) => {
    setError('')
    const updatedRow = { ...newRow }

    await updateRole({
      variables: { userId: updatedRow.id, newRole: updatedRow.role },
    })

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const handleProcessRowUpdateError = React.useCallback((err: Error) => {
    setError(`Något gick fel: ${err.message}`)
  }, [])

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    // do not get out of edit mode when clicking out of the cell
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const deleteUser = async () => {
    if (!userToDelete?.id) {
      return
    }
    try {
      await doDeleteUser({
        variables: {
          id: userToDelete.id,
          organisationId:
            user?.role === Role.SysAdmin
              ? userToDelete.organisation.id
              : undefined,
        },
      })
      setUserToDelete(undefined)
    } catch (err) {
      setError('something went wrong when deleting the user')
    }
  }

  const handleRestrictedAccessClick = async (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    try {
      await changeRestrictionMode({ variables: { restrictedAccess: checked } })
    } catch (err) {
      setError('something went wrong')
    }
  }

  return (
    <Flex column stretchWidth style={{ gap: spacing.large }}>
      <LinkSentPopup
        setRegistrationEmail={setRegistrationEmail}
        registrationEmail={registrationEmail}
      />
      {user?.organisation.orgType &&
        [OrgType.Fortnox].includes(user?.organisation.orgType) && (
          <FullWidthBox>
            <HeadRow>
              <TitleWithSub title='Konfiguration' sub='' />
            </HeadRow>
            <FormGroup style={{ padding: spacing.medium }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user?.organisation.restrictedAccess}
                    onChange={handleRestrictedAccessClick}
                    data-testid='restricted-mod-box'
                  />
                }
                label={
                  <Flex style={{ gap: spacing.small }}>
                    <Body1>Endast registrerade användare kan logga in </Body1>
                    <TooltipInfo
                      info={
                        <Flex column>
                          <Body1>
                            Om detta är markerat måste du registrera användarna
                            med formuläret nedan innan de kan logga in med
                            Fortnox
                          </Body1>
                          <Body1>
                            Om detta inte är markerat kan någon som är
                            registrerad som användare i ditt
                            Fortnox-organisationskonto använda Fortnox för att
                            logga in.
                          </Body1>
                        </Flex>
                      }
                    />
                  </Flex>
                }
              />
            </FormGroup>
          </FullWidthBox>
        )}

      <FlexingBox style={{ width: '100%' }}>
        <HeadRow>
          <TitleWithSub title='Användare' sub='' />
        </HeadRow>
        <SnackAlert
          open={!!error}
          severity='error'
          onClose={() => setError('')}
        >
          <Body1 data-testid='error-msg'>{error}</Body1>
        </SnackAlert>
        <div style={{ padding: spacing.large }}>
          <DataGrid
            rows={rows ?? []}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            disableVirtualization
            autoHeight
          />
          <Dialog
            open={!!userToDelete}
            onClose={() => setUserToDelete(undefined)}
          >
            <DialogTitle align='center'>User deletion confirmation</DialogTitle>
            <Flex
              column
              style={{ margin: spacing.medium, gap: spacing.medium }}
            >
              <Body1>
                <span>Är du säker på att du vill ta bort användare</span>{' '}
                <b>{userToDelete?.username}</b>
              </Body1>
              <Flex row justifyEnd stretchWidth style={{ gap: spacing.medium }}>
                <ButtonGreen onClick={deleteUser} data-testid='yes-delete'>
                  Ja
                </ButtonGreen>
                <ButtonLightBorder
                  onClick={() => {
                    setUserToDelete(undefined)
                  }}
                  data-testid='no-delete'
                >
                  Nej
                </ButtonLightBorder>
              </Flex>
            </Flex>
          </Dialog>
        </div>

        <div style={{ padding: spacing.large }} data-testid='add-user-form'>
          <AddUserForm
            roleOptions={roleOptions}
            setError={setError}
            sysAdmin={sysAdmin}
          />
        </div>
      </FlexingBox>
    </Flex>
  )
}
