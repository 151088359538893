import { ApolloError } from '@apollo/client'
import React, { forwardRef, PropsWithChildren, useContext } from 'react'
import styled from 'styled-components'
import { AppDataContext } from '../context/AppDataContext'
import { Checkbox } from './Checkbox'
import { Flex, FlexingBox, HeadRow } from './Flex'
import { MaybeLoading } from './PageLoader'
import { TitleWithSub } from './TitleWithSub'
import { FilterCategory } from './filter/filter-category/FilterCategory'
import { Category } from '../graphql/generated'
import { IFilterDatePeriod } from '../utils/datePeriod'
import { SelectYear } from './filter/SelectYear'
import { spacing } from '../theme'

const ControlsRow = styled(Flex)`
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #b0b0b0;
  padding: ${spacing.large};
  max-height: 64px;
  gap: ${spacing.medium};
`

const Container = styled(FlexingBox)`
  overflow: visible;
  min-height: 380px;
`

/*
  @media screen and ${device.laptop} {
    width: 70%;
  }*/

type Props = {
  ControlsStart?: React.ReactNode
  ControlsEnd?: React.ReactNode
  titleData: { title: string; subtitle: string }
  useAnnualWorkForce?: boolean
  setUseAnnualWorkForce?: (x: boolean) => void
  loading: boolean
  error?: ApolloError | Error
  errorMessageWhenNoData?: string
  hasData: boolean
  loadingPlaceholderHeight?: number
  tooltip?: React.JSX.Element
  contentWrapperStyle?: React.CSSProperties
  categories?: Category[]
  selectedCategory?: Category
  setCategory?: (cat?: Category) => void
  selectedDatePeriod?: IFilterDatePeriod
  setDatePeriod?: React.Dispatch<React.SetStateAction<IFilterDatePeriod>>
  className?: string
  controlsStyle?: React.CSSProperties
}

// TODO: Allow style overrides.
export const CardTemplate = forwardRef<
  HTMLDivElement,
  PropsWithChildren<Props>
>(
  (
    {
      ControlsStart,
      ControlsEnd,
      titleData,
      useAnnualWorkForce,
      setUseAnnualWorkForce,
      children,
      tooltip,
      errorMessageWhenNoData,
      contentWrapperStyle,
      selectedCategory,
      setCategory,
      selectedDatePeriod,
      setDatePeriod,
      className,
      controlsStyle,
      ...props
    },
    ref,
  ) => {
    const { organisation, orgHasInitializedData } = useContext(AppDataContext)
    const showControlsRow = Boolean(
      (organisation.useAnnualWorkforce && !!setUseAnnualWorkForce) ||
        (!!setDatePeriod && !!selectedDatePeriod) ||
        !!setCategory ||
        ControlsStart ||
        ControlsEnd,
    )
    const { categories } = useContext(AppDataContext)
    const headCategories = categories.filter((x) => x?.children.length > 0)

    return (
      <Container ref={ref} className={className}>
        <HeadRow>
          <TitleWithSub
            title={titleData.title}
            sub={titleData.subtitle}
            infoDescription={tooltip}
          />
        </HeadRow>
        <MaybeLoading
          orgHasInitializedData={orgHasInitializedData}
          {...props}
          defaultErrorMessage={errorMessageWhenNoData}
        >
          <span style={{ flex: 1, ...(contentWrapperStyle ?? {}) }}>
            {children}
          </span>
        </MaybeLoading>
        {showControlsRow && (
          <ControlsRow
            data-testid='card-template-controls-row'
            style={controlsStyle}
          >
            {ControlsStart}
            {!!setCategory && (
              <FilterCategory
                selectedCategory={selectedCategory}
                setCategory={setCategory}
                categories={headCategories}
              />
            )}
            {organisation.useAnnualWorkforce && !!setUseAnnualWorkForce && (
              <Checkbox
                isChecked={!!useAnnualWorkForce}
                label='Visa per anställd'
                // Component is unstable and will re-render on every render, but since it is only displayed during
                // mouse-over and should therefore not be displayed while the parent is changed, this is not a concern.
                // eslint-disable-next-line react/no-unstable-nested-components
                titleTooltipGenerator={() => <TooltipComponent />}
                onChange={() => {
                  setUseAnnualWorkForce(!useAnnualWorkForce)
                }}
              />
            )}
            {!!setDatePeriod && !!selectedDatePeriod && (
              <SelectYear
                selectedDatePeriod={selectedDatePeriod}
                setDatePeriod={setDatePeriod}
              />
            )}
            {ControlsEnd}
          </ControlsRow>
        )}
      </Container>
    )
  },
)

function TooltipComponent() {
  return (
    <>
      <span>Antalet anställda representeras av antalet </span>
      <span style={{ fontStyle: 'italic' }}>årsarbetskrafter</span>
      <span>
        {' '}
        (heltidstjänster över ett år). Ex: två halvtidstjänster som sträcker sig
        över ett år blir tillsammans en årsarbetskraft.
      </span>
    </>
  )
}

export default CardTemplate
