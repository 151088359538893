import _ from 'lodash'
import * as React from 'react'
import {
  Point,
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLabelProps,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory'
import styled from 'styled-components'
import { defaultChartTheme } from '../../chartTheme'
import { getNumberFormatter } from '../../utils/adaptiveNumberFormat'
import { SvgLinePattern } from '../svg/SvgLinePattern'
import {
  emissionAreaColor,
  emissionLineColor,
  guideLineColor,
  interpolatedEmissionAreaColor,
  interpolatedEmissionLineColor,
  planLineColor,
} from './goalGraphColors'
import { getBudgetPlan } from './goalPlanning'
import { makeSvgStopData } from './interpolationUtil'
import { colors, theme } from '../../theme'
import { GoalContext } from '../../context/GoalContext'
import type { DataPoint } from '../../context/GoalContext'
import { Loader } from '../Loader'
import { H6 } from '../Typography'
import { lineChartDotSize, lineChartLineWidth } from '../../utils/chart'
import { usePartialYearEmission } from '../../hooks/usePartialYearEmission'
import { LegendSymbolProps } from '../charts/victory-util/victory-prop-types'
import { ChartLegendOpenDot } from '../charts/victory-util/ChartLegendOpenDot'
import { ChartLabelDashedLine } from '../charts/victory-util/ChartLabelDashedLine'
import {
  budgetSvgPatternId,
  emissionAreaUrlId,
  emissionLineUrlId,
  goalLegendLabelName,
  partialYearLegendLabelName,
  planLegendStripeId,
  planPatternAngle,
  planPatternBackground,
  planPatternForeground,
  planPatternSpacing,
  planPatternStrokeWidth,
  chartNames,
} from './util/chartConstants'
import { ChartCursorLabel } from './util/ChartCursorLabel'
import { valueLabelWithChartName } from './util/labelUtils'

const NoDataContainer = styled.div<{
  width: number
  height: number
  fontSize: number
  // Disable linter: it wants to put the ` next to }>
  // but this messes up the syntax highlighting in vscode somehow.
  // eslint-disable-next-line prettier/prettier
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-size: ${(props) => props.fontSize}px;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
`

/**
 * Legend symbol (legend "data" component) for this goal chart.
 */
function LegendSymbol(props: LegendSymbolProps): React.JSX.Element {
  const { datum } = props ?? {}

  if (datum?.name === partialYearLegendLabelName) {
    return <ChartLegendOpenDot {...props} />
  } else if (datum?.name === goalLegendLabelName) {
    return <ChartLabelDashedLine {...props} />
  }
  return <Point {...props} />
}

type ChartCursorProps = VictoryLabelProps & {
  /** SVG chart height. */
  height: number
  /** Last year with emission data. */
  lastEmissionYear?: number | undefined
}

/**
 * Hover cursor for the goal chart.
 */
function ChartCursor(props: ChartCursorProps): React.JSX.Element {
  const { x, height } = props
  // TODO: Figure out how to avoid hackish constant.
  //       The `height` passed is the full SVG chart, so it overlaps
  //       the legend and goes below the X-axis. This constant happens
  //       to give a pretty good result, visually.
  const outerPadding = 48
  return (
    <g>
      {/* 
        Guide-line; snaps to data with `x` passed by VoronoiContainer,
        takes up approximately the full height of the inner goal chart,
        without overlapping the X-axis or the legend.
      */}
      <line
        x1={x}
        x2={x}
        y1={outerPadding}
        y2={height - outerPadding}
        strokeDasharray='4 2'
        strokeWidth={3}
        stroke='black'
        pointerEvents='none'
      />
      {/* Hover info for the various graphs. */}
      <ChartCursorLabel {...props} />
    </g>
  )
}

export function GoalGraphChart({
  width,
}: {
  width: number
}): React.JSX.Element {
  // Note: The emissions in goal do not contain the partial year (if one exists).
  const { loading, error, data } = React.useContext(GoalContext)

  // Fetch the partial year emissions if it exists,
  // The partial year is never in the trend query result,
  // which is what the goal context uses as the base for emissions.
  const {
    loading: emisLoading,
    error: emisError,
    partialYearEmission,
  } = usePartialYearEmission()

  const lastEmisDataPoint: DataPoint | undefined =
    data?.interpolatedEmissions?.slice(-1)[0]

  const [plan, setPlan] = React.useState<DataPoint[]>([])

  React.useEffect(() => {
    if (
      data &&
      data.interpolatedEmissions.length &&
      data.goalEmissionCurve.length
    ) {
      setPlan(
        getBudgetPlan({
          emissions: data.interpolatedEmissions,
          goalpoints: data.goalEmissionCurve,
        }),
      )
    }
  }, [data])

  const height = Math.max(width / 3, 380)
  const fontSize = theme.typography.fontSize

  if (loading || emisLoading) {
    return (
      <NoDataContainer width={width} height={height} fontSize={fontSize}>
        <Loader borderSize={Math.max(4, height / 24)} size={height / 3} />
      </NoDataContainer>
    )
  } else if (error || emisError) {
    return (
      <NoDataContainer width={width} height={height} fontSize={fontSize}>
        <H6>Något gick fel: kunde inte hämta måldata</H6>
      </NoDataContainer>
    )
  } else if (!data) {
    // Not loading and no errors, the org does not have goals saved.
    // Note: On the settings page, there should always be (synthetic) goal data,
    //       via the GoalSettingsContext.
    return (
      <NoDataContainer width={width} height={height} fontSize={fontSize}>
        <H6>Er organisation har inte satt något mål</H6>
      </NoDataContainer>
    )
  }

  const {
    interpolatedEmissions: emissions,
    goalEmissionCurve: targetCO2eByYear,
    referenceYear,
    targetYear,
    emissionYearRanges,
    emissionYearGaps,
  } = data

  const formatter = getNumberFormatter(emissions.map((p) => p.y))

  const hasData = !!targetCO2eByYear.length

  const yearMinRange = _.min([referenceYear, ...emissions.map((e) => e.x)])
  const yearMaxRange = _.max([targetYear, ...emissions.map((e) => e.x)])

  const allYears =
    _.isNumber(yearMinRange) && _.isNumber(yearMaxRange)
      ? _.range(yearMinRange, 1 + yearMaxRange)
      : []

  const minYear = _.min(allYears)
  const maxYear = _.max(allYears)

  const graphDataStripeStops = makeSvgStopData(emissionYearRanges)

  return (
    <>
      <svg width={0} height={0}>
        <linearGradient id={emissionAreaUrlId}>
          {graphDataStripeStops.map((stop) => (
            <stop
              key={stop.key}
              offset={`${(stop.offset * 100).toFixed(3)}%`}
              stopColor={
                stop.kind === 'data'
                  ? emissionAreaColor
                  : interpolatedEmissionAreaColor
              }
            />
          ))}
        </linearGradient>
        <linearGradient id={emissionLineUrlId}>
          {graphDataStripeStops.map((stop) => (
            <stop
              key={stop.key}
              offset={`${(stop.offset * 100).toFixed(3)}%`}
              stopColor={
                stop.kind === 'data'
                  ? emissionLineColor
                  : interpolatedEmissionLineColor
              }
            />
          ))}
        </linearGradient>
      </svg>
      {/* Plan/budget graph area stripe pattern. */}
      <SvgLinePattern
        referenceID={budgetSvgPatternId}
        spacing={planPatternSpacing}
        strokeWidth={planPatternStrokeWidth}
        stroke={planPatternForeground}
        fill={planPatternBackground}
        angle={planPatternAngle}
      />
      {/* Plan/budget legend icon stripe pattern. */}
      <SvgLinePattern
        referenceID={planLegendStripeId}
        spacing={2}
        strokeWidth={1}
        stroke={emissionAreaColor} // sic: darker than graph area, too hard to see a small icon with light gray.
        angle={planPatternAngle}
      />
      <VictoryChart
        domainPadding={{
          // Padding left/right to add air.
          x: [80, 20],
          // Padding top to prevent potential visual cutting-off of the graph line near the max value,
          // which seems to happen because Victory Chart does not account for stroke width.
          y: [0, 4],
        }}
        width={width}
        height={height}
        theme={defaultChartTheme}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension='x'
            // Part of handling Voronoi treating single- and multi-point hover differently;
            // if `mouseFollowTooltips` is set, the flyout box always follows the mouse (X and Y).
            // If it is not set, the box will either:
            // a) snap to a single data point (X and Y), if there is only one point for the X value
            // b) follow the mouse in Y but snap to the points' X, if there are multiple points with the same X
            mouseFollowTooltips
            // Avoid hover info for some charts:
            voronoiBlacklist={[
              chartNames.emissionArea, // Would be duplicate info with the emission dot.
              chartNames.partialYearLine, // Would duplicate info with the dot, and emissions.
            ]}
            // Note: in order to draw the icons correctly, the labels
            //       created here contain additional formatting information
            //       which is used when the labels get passed into ChartCursor and below.
            //       See `valueLabelWithChartName` and related utils.
            labels={({ datum }: { datum: { y: number; childName: string } }) =>
              valueLabelWithChartName(formatter, datum)
            }
            labelComponent={
              <ChartCursor
                // `height` is only included to satisfy the linter.
                // As long as it is undefined here, it is passed by Victory
                // when the graph is rendered.
                height={undefined as unknown as number}
                // Note that the emissions and the budget overlap for
                // the `lastEmisDataPoint` (emissions end there, the plan begins there)
                // and we only want the label for emissions to show up,
                // but the `labels` function above should only generate strings,
                // and the empty string is still displayed,
                // so pass `lastEmissionYear` so this can be handled inside `ChartCursorLabel`.
                lastEmissionYear={lastEmisDataPoint?.x}
              />
            }
          />
        }
      >
        <VictoryLegend
          orientation='horizontal'
          gutter={20}
          data={[
            ...(hasData
              ? [
                  {
                    name: goalLegendLabelName,
                    symbol: { fill: colors.goalBlue },
                  },
                ]
              : []),
            {
              name: 'Utsläpp',
              symbol: { fill: emissionLineColor },
            },
            ...(partialYearEmission
              ? [
                  {
                    name: partialYearLegendLabelName,
                    symbol: { fill: emissionLineColor },
                  },
                ]
              : []),
            ...(emissionYearGaps.length
              ? [
                  {
                    name: 'Uppskattade utsläpp\n(data saknas)',
                    symbol: {
                      fill: interpolatedEmissionAreaColor,
                    },
                  },
                ]
              : []),
            ...(hasData
              ? [
                  {
                    name: 'Budget',
                    symbol: {
                      fill: `url(#${planLegendStripeId})`,
                      type: 'square',
                    },
                  },
                ]
              : []),
          ]}
          borderPadding={{
            // Hackish: 40 aligns the left-most icon roughly with
            //          the start of the horisontal guides.
            left: 40,
          }}
          dataComponent={<LegendSymbol />}
        />
        {!!hasData && (
          <VictoryArea
            key={chartNames.budgetArea}
            name={chartNames.budgetArea}
            data={plan}
            style={{
              data: {
                fill: `url(#${budgetSvgPatternId})`,
                stroke: planLineColor,
                strokeWidth: lineChartLineWidth,
              },
            }}
          />
        )}
        <VictoryArea
          key={chartNames.emissionArea}
          name={chartNames.emissionArea}
          data={emissions}
          style={{
            data: {
              fill: `url(#${emissionAreaUrlId})`,
              stroke: `url(#${emissionLineUrlId})`,
              strokeWidth: lineChartLineWidth,
            },
          }}
        />
        <VictoryScatter
          key={chartNames.emissionDot}
          name={chartNames.emissionDot}
          data={emissions}
          size={lineChartDotSize}
          style={{
            data: {
              fill: emissionLineColor,
            },
          }}
        />
        {!!lastEmisDataPoint && !!partialYearEmission && (
          <VictoryLine
            key={chartNames.partialYearLine}
            name={chartNames.partialYearLine}
            data={[lastEmisDataPoint, partialYearEmission]}
            style={{
              data: {
                stroke: emissionLineColor,
                strokeDasharray: '6, 6',
                strokeWidth: lineChartLineWidth,
              },
            }}
          />
        )}
        {!!partialYearEmission && (
          <VictoryScatter
            key={chartNames.partialYearDot}
            name={chartNames.partialYearDot}
            size={lineChartDotSize}
            data={[partialYearEmission]}
            style={{
              data: {
                // A fill prevents seeing a line segment inside the circle;
                // the dashed line often intersects the dot.
                fill: colors.white,
                stroke: emissionLineColor,
                strokeWidth: 3,
              },
            }}
          />
        )}
        {!!hasData && (
          <VictoryLine
            key={chartNames.goalLine}
            name={chartNames.goalLine}
            data={targetCO2eByYear}
            style={{
              data: {
                strokeWidth: lineChartLineWidth,
                stroke: colors.goalBlue,
                strokeDasharray: '6, 6',
              },
            }}
          />
        )}
        <VictoryAxis
          tickFormat={(datum: number) =>
            // Hackish: hide ticks for area outside real data (caused by padding the domain).
            !_.isNumber(minYear) ||
            !_.isNumber(maxYear) ||
            datum < minYear ||
            datum > maxYear
              ? ''
              : datum.toFixed(0)
          }
          style={{ axis: { stroke: 'transparent' } }}
        />
        <VictoryAxis
          tickFormat={(datum: number) =>
            formatter.format(datum, { hideUnit: true })
          }
          tickLabelComponent={<VictoryLabel dx={60} dy={-12} />}
          dependentAxis
          // crossAxis is false == Force label at zero.
          crossAxis={false}
          style={{
            axis: { stroke: 'transparent' },
            grid: {
              stroke: guideLineColor,
              strokeDasharray: '4, 8',
            },
            tickLabels: {
              fill: 'gray',
            },
          }}
        />
      </VictoryChart>
    </>
  )
}
