import { ApolloError } from '@apollo/client'
import { useMemo } from 'react'
import {
  Emission,
  EmissionInput,
  useEmissionsQuery,
} from '../graphql/generated'

export function useEmissionData(input: EmissionInput): {
  data: Emission[]
  loading: boolean
  error: ApolloError | undefined
} {
  const { loading, data, error, previousData } = useEmissionsQuery({
    variables: { input },
  })
  return useMemo(() => {
    const emissions = loading ? previousData?.getEmission : data?.getEmission
    return { data: (emissions ?? []) as Emission[], loading, error }
  }, [data?.getEmission, error, loading, previousData?.getEmission])
}
