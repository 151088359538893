import React from 'react'
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridValidRowModel,
  useGridApiContext,
} from '@mui/x-data-grid'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import _ from 'lodash'
import { Section, SectionId, SysadminSectionId } from '../../emission/Section'
import { FlexingBox, HeadRow } from '../../../components/Flex'
import { TitleWithSub } from '../../../components/TitleWithSub'
import {
  Feature,
  OrganisationFragment,
  OrganisationsQuery,
  useUpdateEnabledFeaturesMutation,
} from '../../../graphql/generated'
import { spacing } from '../../../theme'

interface CustomProps {
  id: GridRowId
  value?: string[] | undefined
  field: string
}
function CustomEditComponent(props: CustomProps) {
  const { id, value, field } = props
  const apiRef = useGridApiContext()
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const eventValue = event.target.value // The new value entered by the user
    const newValue =
      typeof eventValue === 'string' ? eventValue.split(',') : eventValue
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
    })
  }

  return (
    <Select
      multiple
      value={value ?? []}
      onChange={handleChange}
      sx={{ width: '100%' }}
    >
      {Object.values(Feature).map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  )
}

interface Props {
  active: {
    scroll: boolean
    sectionId: SectionId
  }
  handleSectionVisibilityChange: (
    sectionId: SectionId,
    isVisible: boolean,
  ) => void
  orgData: OrganisationsQuery | undefined
}

export function Organisations({
  active,
  handleSectionVisibilityChange,
  orgData,
}: Props): React.JSX.Element {
  const organisations = orgData?.organisations
  const [updateEnabledFeatures] = useUpdateEnabledFeaturesMutation()

  const getFeatures = (value: OrganisationFragment, row: GridValidRowModel) => {
    const enabledFeatures = row.enabledFeatures?.features
    if (enabledFeatures) {
      return [...enabledFeatures].sort()
    }
    return []
  }

  const setFeatures = (value: OrganisationFragment, row: GridValidRowModel) => {
    return {
      ...row,
      enabledFeatures: {
        features: value,
      },
    }
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'id', flex: 1 },
    { field: 'name', headerName: 'name', flex: 1 },
    { field: 'orgType', headerName: 'type', flex: 1 },
    { field: 'idp', headerName: 'idp', flex: 1 },
    {
      field: 'enabledFeatures',
      headerName: 'features',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      renderEditCell: (params) => <CustomEditComponent {...params} />,
      valueGetter: getFeatures,
      valueSetter: setFeatures,
      filterable: false,
    },
    {
      field: 'hasTransactions',
      headerName: 'has transactions',
      flex: 1,
      type: 'boolean',
    },
  ]

  const handleCellEditStop = async (
    newRow: OrganisationFragment,
    oldRow: OrganisationFragment,
  ): Promise<OrganisationFragment> => {
    if (
      !_.isEqual(
        newRow.enabledFeatures?.features,
        oldRow.enabledFeatures?.features,
      )
    ) {
      // the feature have been updated, persist to server
      await updateEnabledFeatures({
        variables: {
          orgId: newRow.id,
          features: newRow.enabledFeatures?.features,
        },
      })
    }
    return newRow
  }

  return (
    <Section
      sectionId={SysadminSectionId.organisations}
      active={active}
      onVisibilityChange={handleSectionVisibilityChange}
      key='organisation-section'
    >
      <FlexingBox>
        <HeadRow>
          <TitleWithSub
            title='Organisations'
            sub=''
            infoDescription='list of organisations'
          />
        </HeadRow>
        <div style={{ padding: spacing.large }}>
          <DataGrid
            rows={organisations ?? []}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            processRowUpdate={handleCellEditStop}
          />
        </div>
      </FlexingBox>
    </Section>
  )
}
