import React, { ErrorInfo } from 'react'
import { captureException } from '@sentry/browser'
import { ApolloError } from '@apollo/client'
import { Oops } from '../Oops'
import { Body1 } from '../Typography'
import { appHistory } from '../../utils/history'
import { LogoutError } from './LogoutError'
import { ButtonLightBorder } from '../Buttons'
import { spacing } from '../../theme'

type Props = { children: React.JSX.Element }
export class ErrorBoundary extends React.Component<Props, { error?: Error }> {
  constructor(props: Props) {
    super(props)
    this.state = {
      error: undefined,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    captureException(error, {
      extra: { componentStack: errorInfo.componentStack },
    })
    this.setState({ error })
  }

  render(): React.JSX.Element | undefined {
    const { error } = this.state
    const { children } = this.props

    // When we fail to refresh the token in the errorLink we thorw a logoutError.
    // This error get encapsullated in a GraphqlError in an ApolloError.
    // If we get this error we should log the user out instead of displaying an error message.
    if (
      error instanceof ApolloError &&
      error.graphQLErrors.length === 1 &&
      error.graphQLErrors[0].extensions instanceof LogoutError
    ) {
      appHistory.push('/logout')
      return undefined
    }

    if (error) {
      return (
        <Oops errorMessage={error.message}>
          <ButtonLightBorder
            style={{
              marginTop: spacing.large,
            }}
            onClick={() => this.setState({ error: undefined })}
          >
            <Body1>Ok</Body1>
          </ButtonLightBorder>
        </Oops>
      )
    }

    return children
  }
}
