import React from 'react'
import { Svg, G, Path, Rect, Defs, ClipPath } from '@react-pdf/renderer'

/**
 * SVG using @react-pdf/renderer components: A4-sized background for GHG-p report.
 *
 * This was exported from Figma, and converted to the required component types.
 */
export function BackgroundSvg(): React.JSX.Element {
  return (
    <Svg viewBox='0 0 595 842' fill='none'>
      <Defs>
        <ClipPath id='clip1_4645_5541'>
          <Rect
            width='842'
            height='842'
            transform='translate(-45 694.276) rotate(-17.7915)'
          />
        </ClipPath>
        <ClipPath id='clip2_4645_5541'>
          <Rect
            width='842'
            height='842'
            transform='translate(637.693 12.7733) rotate(159.133)'
          />
        </ClipPath>
      </Defs>
      <G>
        <Rect x={-50} y={-50} width='150%' height='150%' fill='#F7F7F7' />
        <G clip-path='url(#clip1_4645_5541)'>
          <Path
            d='M675.177 533.662L531.578 636.234L718.427 765.642L675.177 533.662Z'
            fill='#FAFAFA'
          />
          <Path
            d='M531.744 636.753L515.419 709.618L718.548 765.767L531.744 636.753Z'
            fill='#F0F0F0'
          />
          <Path
            d='M531.796 636.655L342.293 614.04L379.23 672.76L515.42 709.618L531.796 636.655Z'
            fill='#FAFAFA'
          />
          <Path
            d='M379.256 672.588L202.1 894.081L58.3456 883.147L342.292 614.04L379.256 672.588Z'
            fill='#F0F0F0'
          />
          <Path
            d='M379.256 672.588L492.073 920.314L620.934 931.524L718.846 765.671L379.256 672.588Z'
            fill='white'
          />
          <Path
            d='M379.256 672.588L105.209 1015.15L492.074 920.314L379.256 672.588Z'
            fill='#FAFAFA'
          />
        </G>
        <G clip-path='url(#clip2_4645_5541)'>
          <Path
            d='M-72.8295 211.795L65.0595 101.666L-128.463 -17.5312L-72.8295 211.795Z'
            fill='#FAFAFA'
          />
          <Path
            d='M64.8652 101.156L77.2577 27.5208L-128.591 -17.649L64.8652 101.156Z'
            fill='#F0F0F0'
          />
          <Path
            d='M64.8197 101.257L255.263 113.672L215.229 57.0187L77.2572 27.5208L64.8197 101.257Z'
            fill='#FAFAFA'
          />
          <Path
            d='M215.212 57.1921L380.23 -173.486L524.363 -170.281L255.263 113.672L215.212 57.1921Z'
            fill='#F0F0F0'
          />
          <Path
            d='M215.212 57.1921L89.2663 -184.125L-40.0099 -188.405L-128.883 -17.538L215.212 57.1921Z'
            fill='white'
          />
          <Path
            d='M215.212 57.1921L470.486 -299.579L89.2662 -184.125L215.212 57.1921Z'
            fill='#FAFAFA'
          />
        </G>
      </G>
    </Svg>
  )
}
