import React from 'react'
import { Text, G, Path, Line } from '@react-pdf/renderer'
import {
  LineSegmentProps,
  TextProps,
  VictoryCommonPrimitiveProps,
  VictoryLabel,
  VictoryLabelProps,
  VictoryPrimitiveShapeProps,
} from 'victory'

/**
 * Victory -> @react-pdf/renderer bridge component for `dataComponent` in `VictoryPie`.
 */
export function PathBridge(
  props: VictoryPrimitiveShapeProps & React.SVGProps<SVGPathElement>,
): React.JSX.Element {
  const { d, transform, style } = props
  const { fill, stroke, strokeWidth } = style ?? {}
  if (!d) {
    return <G />
  }
  return (
    <Path
      d={d}
      transform={transform}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  )
}

/**
 * Victory -> @react-pdf/renderer bridge component for `VictoryLabel`.
 */
export function LabelBridge(props: VictoryLabelProps): React.JSX.Element {
  const { style } = props
  return (
    <VictoryLabel
      {...props}
      style={{
        ...style,
        textAlign: 'left',
      }}
      groupComponent={<G />}
      textComponent={<LabelTextBridge />}
      backgroundComponent={<G />}
    />
  )
}

/**
 * Victory -> @react-pdf/renderer bridge component, for the `textComponent` in a `VictoryLabel`.
 */
export function LabelTextBridge(
  props: TextProps & React.SVGProps<SVGTextElement>,
): React.ReactElement {
  const { children, y } = props
  if (typeof y !== 'number') {
    // We need y to be a number;
    // This should never happen.
    return <G />
  }

  type TspanComponent = React.ReactElement<
    VictoryCommonPrimitiveProps & React.SVGProps<SVGTSpanElement>
  >

  // Victory simply gives the type of children as "ReactNode", but empirically it
  // is determined to be a list of lines of tspan elements, even
  // if only a single label is used. (true for victory 37.2.0).
  const lines: TspanComponent[] = []
  if (Array.isArray(children)) {
    if (!children?.length) {
      return <G />
    }
    lines.push(...children)
  } else {
    // Should never happen
    lines.push(children as TspanComponent)
  }
  // Victory has split lines in elements by newline characters,
  // and has computed the x/y positions of the texts,
  // but it assumes the "dx" and "dy" properties will be respected,
  // i.e. that the texts will be placed relative to the previously drawn
  // text element by using these values, but @react-pdf/renderer does not do that,
  // so we have to do it manually by tallying the offsets.
  let dx = 0
  let dy = 0
  const res = lines.map(({ props: lnProps }) => {
    const {
      x: lnX,
      dx: lnDx,
      dy: lnDy,
      children: lnText, // child is a string
      style: lnStyle,
      textAnchor,
    } = lnProps
    dx += Number(lnDx!)
    dy += Number(lnDy!)
    return (
      <Text
        key={`${lnX}-${lnText}`}
        x={Number(lnX) + dx}
        y={y + dy}
        style={{
          ...lnStyle,
        }}
        // Incomming textAnchor is typed as `string | undefined`
        textAnchor={textAnchor as 'start' | 'middle' | 'end' | undefined}
      >
        {lnText}
      </Text>
    )
  })
  return <G>{res}</G>
}

/**
 * Victory -> @react-pdf/renderer bridge component for `labelIndicator` in `VictoryPie`.
 */
export function LabelIndicatorBridge(
  props: LineSegmentProps,
): React.JSX.Element {
  const { x1, y1, x2, y2 } = props
  return <Line x1={x1!} y1={y1!} x2={x2!} y2={y2!} stroke='black' />
}
