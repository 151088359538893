import { Font, StyleSheet } from '@react-pdf/renderer'
import { colors, theme } from '../../../theme'
import RobotoFont from '../../../assets/fonts/Roboto-Regular.ttf'
import RobotoBoldFont from '../../../assets/fonts/Roboto-Bold.ttf'

//
//  This file contains PDF styles for use with `@react-pdf/renderer`.
//  The styles are based on designs in Figma,
//  which assumes a standard 72 PPI 'px' A4,
//  meaning the document dimensions are:
//  595 px x 842 px
//  and numeric constants here map 1:1 to that,
//  1 (pt) = 1 px.
//
// As these constants are copied from a design in Figma
// specifically designed as an A4 PDF,
// these sizes are probably not correlated to
// the gaps/sizes used for the web page even when the values
// are equal, so no size constants from the web theme are used.
//
// If we make other PDFs, we should extract a general pattern
// and name these constants something meaningful.
//

export const fontSize8 = 8
const fontSize10 = 10
const fontSize12 = 12
export const fontSize14 = 14
const fontSize20 = 20
export const fontSize32 = 32

const height10 = 10
const height20 = 20
const height22 = 22
const height33 = 33
const height46_36 = 46.36
const height61 = 61
const height80 = 80
const height141 = 141
const height380 = 380

const width10 = 10
const width16 = 16
const width36 = 36
const width60 = 60
const width467 = 467

const gap1 = 1
const gap4 = 4
const gap8 = 8
const gap16 = 16
const gap24 = 24

const letterSpacing0_4 = 0.4
const letterSpacing0_17 = 0.17

const pagePadding = 32

const tableRowHeight = 18

// Pie has fixed dimensions in the GHGP PDF.
export const ghgpPieHeight = 155
export const ghgpPieWidth = 155

// Font family used in the PDF.
// IMPORTANT: Some constants used for the PDF layout are dependent
//            on the font. If the font family is changed, make
//            sure to check the generated PDF and compare to the design.
export const pdfFontFamily = 'Roboto'

// For '@react-pdf/renderer' to be able to use a specific font,
// it must be registered. Font variants, like "bold", must
// be registered separately.
// Note:
// The Roboto font we use for the general page is loaded from google,
// but that API yields a CSS file, and the pdf package requires fonts
// as either TTF or WOFF.
Font.register({
  family: pdfFontFamily,
  fonts: [
    {
      src: RobotoFont,
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: RobotoBoldFont,
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
  ],
})

/**
 * Styles for the GHGp "certificate" PDF.
 */
export const ghgpCertStyle = StyleSheet.create({
  page: {
    height: '100%',
    fontFamily: pdfFontFamily,
    // Text is generally a mix of granite dark/light, which
    // is our main palette. Default to dark for all text.
    color: theme.palette.secondary.dark,
  },
  backgroundImageContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  pageContainer: {
    flexDirection: 'column',
    padding: pagePadding,
    width: '100%',
    height: '100%',
  },
  pageHeader: {
    height: height80,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end', // align to right side of page
    justifyContent: 'space-between', // logo top, text bottom
  },
  dateStamp: {
    height: '100%',
    alignContent: 'flex-start', // top of header
  },
  dateStampText: {
    fontSize: fontSize8,
    color: theme.palette.secondary.light,
  },
  pageHeaderRight: {
    height: '100%',
    flexDirection: 'column',
    alignItems: 'flex-end', // align to right side of page
    justifyContent: 'space-between', // logo top, text bottom
  },
  logoContainer: {
    width: '100%',
    height: height46_36,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  createdByContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end', // right-align
    gap: gap4,
    letterSpacing: letterSpacing0_4,
    color: theme.palette.secondary.light,
  },
  contentHeader: {
    width: '100%',
    height: height61,
    letterSpacing: letterSpacing0_4,
    gap: gap8,
  },
  graphHeader: {
    height: height33,
    fontSize: fontSize20,
    fontWeight: 'bold',
    letterSpacing: letterSpacing0_4,
  },
  mainContentContainer: {
    width: '100%',
    height: height380,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  pieContainer: {
    width: ghgpPieWidth,
    // Height of whole container,
    // the pie/table are smaller and should be
    // centred vertically in this container.
    height: '100%',
    justifyContent: 'center',
  },
  // -----
  // The period (e.g. 2023) should be displayed horisontally centered
  // 24 px above the pie chart, but note that the pie should be
  // vertically centered in the container (and line up with the table).
  // To achieve this, the text is absolutely positioned relative the
  // container of the pie.
  pieAnchor: {
    width: '100%',
    height: ghgpPieHeight,
    position: 'relative',
  },
  piePeriodTextContainer: {
    position: 'absolute',
    width: '100%',
    // Align left with the outer border of the pie circle
    left: 0,
    // Moving period text above pie:
    // The top of the text block is the y-coordinate of
    // the block, so the block height must be compensated for.
    // 16 = height of text block, 24 = desired padding
    // ASSUMING: Roboto (regular) size 14, so written
    // here as "fontSize14 + 2" to indicate font dependency.
    top: -(fontSize14 + 2) - gap24,
  },
  // -----
  piePeriodText: {
    fontFamily: pdfFontFamily,
    fontSize: fontSize14,
    textAlign: 'center',
  },
  graphDescriptionFooter: {
    height: height22,
    fontSize: fontSize8,
    letterSpacing: letterSpacing0_17,
    marginLeft: 'auto',
    color: theme.palette.secondary.light,
  },
  tableContainer: {
    height: '100%',
    paddingLeft: gap16,
    justifyContent: 'center',
  },
  pageFooterContainer: {
    width: width467,
    height: height141,
    gap: gap16, // between paragraphs and the "signature".
    fontSize: fontSize10,
    letterSpacing: letterSpacing0_4,
    flexDirection: 'column',
  },
  pageFooterParagraphContainer: {
    gap: gap8,
    color: theme.palette.secondary.light,
  },
  createdByText: { textAlign: 'right', fontSize: fontSize8 },
  certHeading: { fontSize: fontSize12, height: height20 },
  certScopeHeading: {
    height: height33,
    fontSize: fontSize20,
    fontWeight: 'bold',
    color: colors.ghgpScopeIndigo,
  },
  ceoSignText: { fontWeight: 'bold' },

  //
  // GHGp scope table styles
  //
  scopeTable: {
    flexDirection: 'column',
    width: '100%',
  },
  stRow: {
    flexDirection: 'row',
    height: tableRowHeight, // ensuring equal table rows
    width: '100%',
    fontSize: fontSize10,
  },
  stIconCell: {
    width: width16,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stIconCircle: { borderRadius: 5, width: width10, height: height10 },
  stScopeCell: {
    width: width60,
    textAlign: 'left',
    justifyContent: 'center',
  },
  stEmissionCell: {
    width: width60,
    textAlign: 'right',
    justifyContent: 'center',
  },
  stPercentCell: {
    width: width36,
    textAlign: 'right',
    justifyContent: 'center',
  },
  //
  // / GHGp scope table styles
  //

  //
  // General utility styles
  //
  dividerLine: { width: '100%', height: gap1, backgroundColor: colors.gray },
  spacer8: {
    height: gap8,
    width: '100%',
  },
  spacer16: {
    height: gap16,
    width: '100%',
  },
  //
  // / General utility styles
  //
})
