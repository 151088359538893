import React, { useContext } from 'react'
import { TooltipInfo } from '../../../components/InfoIcon'
import { MaybeLoading } from '../../../components/PageLoader'
import {
  ChangePercentage,
  TrendPercentage,
} from '../../../components/TrendPercentage'
import { H3 } from '../../../components/Typography'
import { AppDataContext } from '../../../context/AppDataContext'
import { getNumberFormatter } from '../../../utils/adaptiveNumberFormat'
import { toCategoryMap } from '../../../utils/category'
import { testHasData } from '../../../utils/testHasData'
import { useMetaData } from './useMetaData'
import {
  Stat,
  StatContent,
  StatFooter,
  StatHeader,
  StatTitle,
  Wrapper,
} from './metaDataCardStyle'
import { ChangeOrGoalCard } from './ChangeOrGoalCard'

export const MetaData: React.FC<ReturnType<typeof useMetaData>> = React.memo(
  ({ metaData, datePeriod, loading, error }) => {
    const { categories, orgHasInitializedData } = useContext(AppDataContext)
    const catmap = toCategoryMap(categories)

    // Load data for selected year and the prior year, to enable display of change in best/worst category.

    const bestCatPercentage = metaData?.bestCategory?.percentage
    const bestCatDiff = metaData?.bestCategory?.diff

    const worstCatPercentage = metaData?.worstCategory?.percentage
    const worstCatDiff = metaData?.worstCategory?.diff

    const specificYearSelected = !Number.isNaN(
      Number.parseInt(datePeriod.name ?? '', 10),
    )
    const showing_partial_year = !!datePeriod.partialYear
    const showing_all_years = !specificYearSelected

    const rangeText = showing_partial_year
      ? `${datePeriod.name} jmf ${parseInt(datePeriod.name, 10) - 1} (${
          datePeriod.partialPeriodText
        })`
      : showing_all_years
        ? 'alla år'
        : `${datePeriod.name} jmf ${parseInt(datePeriod.name, 10) - 1}`

    const bccStr =
      (bestCatDiff !== undefined &&
        getNumberFormatter([bestCatDiff], { showSeparateSign: true }).format(
          bestCatDiff,
        )) ||
      ''

    const wccStr =
      (worstCatDiff !== undefined &&
        getNumberFormatter([worstCatDiff], { showSeparateSign: true }).format(
          worstCatDiff,
        )) ||
      ''

    // HACK: Selecting the percent component like this means they must have a common interface
    const PercentComponent = showing_all_years
      ? TrendPercentage
      : ChangePercentage
    return (
      <Wrapper>
        <Stat>
          <StatHeader>
            <StatTitle>
              <span>
                {loading || (worstCatPercentage && worstCatPercentage >= 0)
                  ? 'Störst ökning'
                  : 'Ingen ökning'}
              </span>
              <br />
              {rangeText}
            </StatTitle>
            <TooltipInfo info='Visar den kategori som har ökat mest i totala utsläpp det senaste avslutade året jämfört med föregående.' />
          </StatHeader>
          <MaybeLoading
            loading={loading}
            error={error}
            hasData={testHasData(metaData?.worstCategory)}
            orgHasInitializedData={orgHasInitializedData}
          >
            <StatContent>
              <PercentComponent
                iconSize={42}
                TextComponent={H3}
                percentage={Math.max(0, worstCatPercentage ?? 0)}
                sign={
                  worstCatPercentage && worstCatPercentage < 0 ? '+' : undefined
                }
              />
              {!showing_all_years &&
                !!worstCatPercentage &&
                worstCatPercentage >= 0 &&
                wccStr}
            </StatContent>
          </MaybeLoading>
          <StatFooter>
            {!loading &&
              (!worstCatPercentage || worstCatPercentage < 0
                ? 'Utsläppen har inte ökat inom någon kategori.'
                : (catmap[metaData?.worstCategory?.categoryId ?? '']?.name ??
                  ''))}
          </StatFooter>
        </Stat>
        <Stat>
          <StatHeader>
            <StatTitle>
              <span>
                {loading || (bestCatPercentage && bestCatPercentage <= 0)
                  ? 'Störst minskning'
                  : 'Ingen minskning'}
              </span>
              <br />
              {rangeText}
            </StatTitle>
            <TooltipInfo info='Visar den kategori som har minskat mest i totala utsläpp det senaste avslutade året jämfört med föregående.' />
          </StatHeader>
          <MaybeLoading
            loading={loading}
            error={error}
            hasData={testHasData(metaData?.bestCategory)}
            orgHasInitializedData={orgHasInitializedData}
          >
            <StatContent>
              <PercentComponent
                iconSize={42}
                TextComponent={H3}
                percentage={Math.min(0, bestCatPercentage ?? 0)}
                sign={
                  bestCatPercentage && bestCatPercentage > 0 ? '-' : undefined
                }
              />
              {!showing_all_years &&
                !!bestCatPercentage &&
                bestCatPercentage <= 0 &&
                bccStr}
            </StatContent>
          </MaybeLoading>
          <StatFooter>
            {!loading &&
              (!bestCatPercentage || bestCatPercentage > 0
                ? 'Utsläppen har inte minskat inom någon kategori.'
                : (catmap[metaData?.bestCategory?.categoryId ?? '']?.name ??
                  ''))}
          </StatFooter>
        </Stat>

        <ChangeOrGoalCard rangeText={rangeText} />
      </Wrapper>
    )
  },
)
