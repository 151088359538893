import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  ACTIONS,
  CallBackProps,
  Events,
  EVENTS,
  STATUS,
  Step,
} from 'react-joyride'
import { captureException } from '@sentry/browser'
import type Svalna from '../@types'
import { Body1, H5 } from '../components/Typography'
import { AppDataContext } from './AppDataContext'
import {
  emissionTourClassNames,
  StepId,
  tourNavbarClassName,
} from '../components/tour/tourConstants'
import { contentMinWidthPx } from '../components/LayoutContent'
import { sideColumnMinWidthRem } from '../components/TableOfContent'
import { navbarWidth, contentPadding, spacing } from '../theme'
import { TourId, useUpdateUserTourMutation } from '../graphql/generated'
import { Flex } from '../components/Flex'
import { SnackAlert } from '../components/SnackAlert'

/**
 * Minimum width for the Joyride overlay.
 * Tied to the widths the page contents will have at the time of writing.
 * Explanation: the overlay is a "position: absolute" div, which is as wide
 * as the window. Since it exists in its own stacking context, it cannot react
 * to the width of the page contents;
 * When the page has horisontal scroll, the overlay does not normally
 * cover the whole width of the page.
 * This value is the length of the minimum width of all the content,
 * when the page needs horisontal scroll.
 * Note: some of the constants are strings and have their own
 * CSS units as part of the string, others are numbers.
 *
 * Note: the "spacing.large" is specific to the emissions page.
 *
 * TODO: If we add more tours, the shared constants should be refactored.
 */
const minOverlayWidth = `calc(${navbarWidth} + 2 * ${sideColumnMinWidthRem}rem + ${contentMinWidthPx}px + 2 * ${contentPadding} + 2 * ${spacing.large})`

// `step.data` is a field  with type `any`. It represents custom data
// assigned per step. Here, the type of the `data` field is augmented,
// so we have access to static type info.
type StepData = {
  /** Unique ID for the step */
  id: StepId
  /**
   * The `scrollOffset` prop on `Joyride` controls how much Joyride will
   * scroll down when highlighting a component. It effectively acts as
   * a padding over the top of the highlighted area, causing it to
   * be further down the screen with a larger `scrollOffset` value.
   * Some components need a bit of extra scroll to be fully visible.
   *
   * This is a function since there can be cases where the offset
   * for a step cannot be static.
   */
  scrollOffset?: () => number
}
type TourStep = Omit<Step, 'data'> & { data?: StepData }
type TourCallbackProps = Omit<CallBackProps, 'step'> & { step: TourStep }
type TourCallback = (data: TourCallbackProps) => void

/**
 * TODO: If we add more tours, refactor this into a shared base type,
 *       and have each tour extend it.
 *       The `Tour` component should only need general state
 *       which can be shared by the types, like `steps`,
 *       and no tour-specific data like `pieAccordionOpen`.
 */
type EmissionTourContextData = {
  /**
   * True if the tour is ongoing, even if no step
   * is currently "active" / no tooltip is currently shown.
   */
  ongoing: boolean
  startTour: () => void
  callback: TourCallback
  /**
   * True if the tour is currently displaying a tooltip.
   * In Joyride "controlled mode", this may temporarily be
   * set to false while waiting for UI updates,
   * but the tour is expected to continue shortly
   * if it is still `ongoing`.
   */
  tourActive: boolean
  steps: TourStep[]
  setSteps: React.Dispatch<React.SetStateAction<TourStep[]>>
  stepIndex: number
  minOverlayWidth: string
  tourSeen: boolean
  //
  // These states are related to controlled behaviour,
  // where certain components should be in particular states
  // during some steps of the tour.
  /**
   * True when the pie accordion has been programatically opened
   * by the pie component, during the relevant tour step.
   */
  pieAccordionOpen: boolean
  setPieAccordionOpen: React.Dispatch<React.SetStateAction<boolean>>
  /**
   * True when the pie accordion has been programatically closed
   * by the pie component, during the relevant tour step.
   */
  pieAccordionClosed: boolean
  setPieAccordionClosed: React.Dispatch<React.SetStateAction<boolean>>
}

export const EmissionTourContext = React.createContext<EmissionTourContextData>(
  {
    ongoing: false,
    startTour: () => {},
    callback: () => {},
    tourActive: false,
    steps: [],
    setSteps: () => {},
    stepIndex: 0,
    tourSeen: true,
    pieAccordionOpen: false,
    setPieAccordionOpen: () => {},
    pieAccordionClosed: false,
    setPieAccordionClosed: () => {},
    minOverlayWidth,
  },
)

export function EmissionTourContextProvider({
  children,
}: Svalna.PropWithChildren): React.JSX.Element {
  const { orgUnits, userTours } = useContext(AppDataContext)
  const tourSeen = !!userTours.find(
    (t) => t.id === TourId.EmissionTour && t.seen,
  )

  // Server-side mutation for updating if the user has seen the tour.
  const [updateUserTourMutation] = useUpdateUserTourMutation()

  // Track if the server mutation failed, so the user can get a sensible
  // message, and an idea of why they may see the tour again.
  const [updateTourSeenError, setUpdateTourSeenError] = useState(false)

  const [ongoing, setOngoing] = useState(false)
  const [tourActive, setTourActive] = useState(false)
  const [pieAccordionOpen, setPieAccordionOpen] = useState(false)
  const [pieAccordionClosed, setPieAccordionClosed] = useState(false)

  const [steps, setSteps] = useState<TourStep[]>([
    {
      data: {
        id: StepId.e1EmissionNav,
      },
      target: `.${emissionTourClassNames[StepId.e1EmissionNav]}`,
      title: <H5>Få koll på utsläppen</H5>,
      content: (
        <Body1>
          I vyn Utsläpp ser du utsläppen från de varor och tjänster som din
          organisation köper in, samt den energi som används.
        </Body1>
      ),
      disableBeacon: true,
      placement: 'right',
      isFixed: true,
    },
    {
      data: {
        id: StepId.e2PieDiagram,
        // The pie diagaram requires a large offset to be visible,
        // otherwise it's hidden under the page header.
        scrollOffset: () => 350,
      },
      target: `.${emissionTourClassNames[StepId.e2PieDiagram]}`,
      title: <H5>Hur fördelas utsläppen?</H5>,
      content: (
        <Body1>
          I det här diagrammet visas utsläpp per kategori. Visa underkategorier
          genom att dubbelklicka på en kategori eller klicka på pilen på samma
          rad.
        </Body1>
      ),
      disableBeacon: true,
      // The arrow acts weird on certain screen sizes if 'left' is used,
      // allow free placement on these sizes.
      // TODO: either find a way to fix the underlying issue, or
      //       also update steps dynamically if screen size changes,
      //       this will only apply if the page is refreshed.
      placement: window.innerWidth >= 1600 ? 'left' : 'top-start',
    },
    {
      data: {
        id: StepId.e3PieAccordion,
        scrollOffset: () => {
          // Target is the accordion in the pie (suppliers list).
          // If the window is tall enough, have the scroll fit the pie table
          // as well, so the user can see the row being selected.
          // If the window is not tall, only scroll to the accordion part.
          if (window.innerHeight > 700) {
            return 550
          } else {
            return 170
          }
        },
      },
      target: `.${emissionTourClassNames[StepId.e3PieAccordion]}`,
      title: <H5>Leverantörer och konton</H5>,
      content: (
        <Body1>
          Här visas vilka leverantörer och bokföringskonton som står för mest
          utsläpp inom den markerade kategorin.
        </Body1>
      ),
      disableBeacon: true,
      // TODO: dito as other pie step: react to screen size changes.
      placement: window.innerWidth >= 1600 ? 'left' : 'top-start',
    },
    // Only include this step if org has units:
    ...(orgUnits.length
      ? ([
          {
            data: {
              id: StepId.e4PerOrg,
            },
            target: `.${emissionTourClassNames[StepId.e4PerOrg]}`,
            title: <H5>Utsläpp per avdelning</H5>,
            content: (
              <Body1>
                Här kan du välja vilken del av organisationen som du vill se
                utsläpp för.
              </Body1>
            ),
            disableBeacon: true,
            placement: 'bottom',
            isFixed: true,
          },
        ] as TourStep[])
      : []),
    {
      data: {
        id: StepId.e5SupportNav,
      },
      target: `.${emissionTourClassNames[StepId.e5SupportNav]}`,
      title: <H5>Dags att utforska!</H5>,
      content: (
        <Body1>
          Nu kan du själv kolla runt. Besök supportsidan om du behöver mer
          hjälp. Lycka till! 🥳
        </Body1>
      ),
      disableBeacon: true,
      placement: 'right-end',
      isFixed: true,
    },
  ])
  const [stepIndex, setStepIndex] = useState(0)

  const [fixScroll, setFixScroll] = useState(false)

  const startTour = useCallback(() => {
    setStepIndex(0)
    setOngoing(true)
    setTourActive(true)
  }, [])

  // Fix nav bar scroll style after the tour has completed;
  // Joyride automatically adds "overflow: initial" to the nav bar,
  // which must be removed after, as it breaks nav bar scroll on small screens.
  // Joyride will keep re-setting the CSS style for a bit even after
  // the final tour event is received in the callback, so an effect
  // with a short timer is used to reset the style.
  useEffect(() => {
    if (fixScroll && !ongoing) {
      const tid = setTimeout(() => {
        const navElem = document.getElementsByClassName(
          tourNavbarClassName,
        )[0] as undefined | HTMLElement
        if (navElem && 'style' in navElem) {
          navElem.style.overflowX = ''
          navElem.style.overflowY = ''
          navElem.style.overflow = ''
        }
        setFixScroll(false)
      }, 500)
      return () => {
        clearTimeout(tid)
      }
    }
  }, [fixScroll, ongoing])

  // If the current step is the pie accordion,
  // wait for the notification that it has been opened
  // before continuing the tour (also see handling in callback).
  useEffect(() => {
    if (
      steps[stepIndex]?.data?.id === StepId.e3PieAccordion &&
      pieAccordionOpen
    ) {
      setTourActive(true)
      setPieAccordionOpen(false)
    } else if (
      steps[stepIndex]?.data?.id === StepId.e2PieDiagram &&
      pieAccordionClosed
    ) {
      setTourActive(true)
      setPieAccordionClosed(false)
    }
  }, [pieAccordionOpen, pieAccordionClosed, steps, stepIndex])

  // Callback for Joyride state.
  // Since we need to use "controlled mode" in order to change component
  // states (e.g. opening the pie card accordion), this callback
  // must update the step index and other state as appropriate.
  const callback = useCallback<TourCallback>(
    (data) => {
      const { action, index, status, type, step } = data
      const { data: stepData } = step

      // When the tour ends, `tourActive` must be set to false for the
      // user to be able to restart the tour.
      if (
        ([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status) ||
        action === ACTIONS.CLOSE
      ) {
        setOngoing(false)
        setTourActive(false)
        setStepIndex(0)
        // Note: Since this mutation returns the new state of the single
        //       updated object, the local GQL cache is updated correctly,
        //       and the me-query will also reflect the update:
        //       AppDataContext will re-render with only the tour
        //       state changing.
        updateUserTourMutation({
          variables: {
            tourID: TourId.EmissionTour,
            seen: true,
          },
        }).catch((e) => {
          setUpdateTourSeenError(true)
          captureException(e)
        })

        // Undo the CSS overflow modifications Joyride adds;
        // see effect for "fixScroll".
        setFixScroll(true)
        return
      }

      // At the end of a step, prepare the state for the next step.
      if (
        ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as Events[]).includes(
          type,
        )
      ) {
        // If we are about to navigate to the pie accordion step,
        // either forward from previous step or back from following step,
        // we must wait for the pie component to open the accordion,
        // or the highlighting will not work properly.
        if (
          (stepData?.id === StepId.e2PieDiagram && action === ACTIONS.NEXT) ||
          (stepData?.id === StepId.e4PerOrg && action === ACTIONS.PREV)
        ) {
          // pause tour until accordion is open (see useEffect).
          setTourActive(false)
        }

        // Going back from the pie accordion being open, to the pie chart.
        // For the highlight to be correct, the accordion must first close.
        if (stepData?.id === StepId.e3PieAccordion && action === ACTIONS.PREV) {
          // pause tour until accordion is closed (see useEffect).
          setTourActive(false)
        }

        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
        setStepIndex(nextStepIndex)
      }
    },
    [updateUserTourMutation],
  )

  const state = useMemo<EmissionTourContextData>(
    () => ({
      ongoing,
      startTour,
      callback,
      tourActive,
      steps,
      setSteps,
      stepIndex,
      pieAccordionOpen,
      setPieAccordionOpen,
      pieAccordionClosed,
      setPieAccordionClosed,
      minOverlayWidth,
      tourSeen,
    }),
    [
      ongoing,
      startTour,
      callback,
      tourActive,
      steps,
      stepIndex,
      pieAccordionOpen,
      pieAccordionClosed,
      tourSeen,
    ],
  )

  return (
    <EmissionTourContext.Provider value={state}>
      <SnackAlert
        open={updateTourSeenError}
        severity='warning'
        onClose={() => setUpdateTourSeenError(false)}
      >
        <Flex style={{ gap: spacing.medium }}>
          <Body1>
            Kunde inte spara att du sett rundturen, den kan komma att visas för
            dig igen
          </Body1>
        </Flex>
      </SnackAlert>

      {children}
    </EmissionTourContext.Provider>
  )
}

/**
 * Use the tour context.
 *
 * Convenience hook to reduce boilerplate.
 *
 * @returns The tour context.
 */
export function useEmissionTourContext(): EmissionTourContextData {
  return useContext(EmissionTourContext)
}
