import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { Body1, H5, S2Bold } from '../../../components/Typography'
import { Form } from '../components/Form'
import { ButtonGreen, ButtonWhiteBorder } from '../../../components/Buttons'
import { toErrorMap } from '../../../utils/toErrorMap'
import { spacing } from '../../../theme'
import {
  ErrorType,
  useFinishOrgRegistrationMutation,
  useFinishUserRegistrationMutation,
  useRenewOrgRegistrationLinkMutation,
  useRenewUserRegistrationLinkMutation,
} from '../../../graphql/generated'
import { LoginType } from '../LoginSelector'
import { SnackAlert, SnackAlertProps } from '../../../components/SnackAlert'

interface Props {
  registrationCode: string
  userCode: string
}

export function AccountCreation({
  registrationCode,
  userCode,
}: Props): React.JSX.Element {
  const history = useHistory()
  const [finishOrgRegistration] = useFinishOrgRegistrationMutation()
  const [renewOrgRegistrationLink] = useRenewOrgRegistrationLinkMutation()
  const [finishUserRegistration] = useFinishUserRegistrationMutation()
  const [renewUserRegistrationLink] = useRenewUserRegistrationLinkMutation()

  const [accountCreated, setAccountCreated] = useState(false)
  const [snackbar, setSnackbar] = useState<SnackAlertProps>({})

  const setError = (msg: string) => {
    setSnackbar({
      open: true,
      severity: 'error',
      children: msg,
      onClose: () => setSnackbar({}),
    })
  }

  const renewLink = async () => {
    if (userCode) {
      await renewUserRegistrationLink({
        variables: { registrationCode: userCode },
      })
    } else {
      await renewOrgRegistrationLink({
        variables: { registrationCode },
      })
    }
    setSnackbar({
      open: true,
      severity: 'success',
      children:
        'Ett meddelande har skickats till din e-post om adressen finns registrerad hos oss.',
      onClose: () => setSnackbar({}),
    })
  }

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    handleBlur,
    errors,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: { password: '', passwordRepeat: '' },
    onSubmit: async (v, { setErrors }) => {
      let errs
      let success = false
      if (userCode) {
        const res = await finishUserRegistration({
          variables: {
            registrationCode: userCode,
            password: v.password,
          },
        })
        errs = res.data?.finishUserRegistration.errors
        success = !!res.data?.finishUserRegistration.success
      } else {
        const res = await finishOrgRegistration({
          variables: {
            registrationCode,
            password: v.password,
          },
        })
        errs = res.data?.finishOrgRegistration.errors
        success = !!res.data?.finishOrgRegistration.success
      }
      if (errs) {
        const errorMap = toErrorMap(errs)
        setErrors(errorMap)

        if (errorMap.registrationCode) {
          const regError = errs.find((e) => e.field === 'registrationCode')
          // should always be true, just here for typescript to know it
          if (regError) {
            switch (regError.errorType) {
              case ErrorType.RegistrationCodeExpired:
              case ErrorType.NotApproved:
                setSnackbar({
                  open: true,
                  severity: 'error',
                  children:
                    'Registreringslänken du använde har upphört att gälla.',
                  action: (
                    <ButtonWhiteBorder
                      size='small'
                      onClick={renewLink}
                      data-testid='renew-button'
                    >
                      Skicka ny länk
                    </ButtonWhiteBorder>
                  ),
                })
                break
              default:
                setError(errorMap.registrationCode)
            }
          } else {
            // should not happen
            setError('något gick fel')
          }
        }
        if (errorMap.user) {
          // there is already a user with the same email in our database,
          setError(
            'E-postadressen du använde under din registrering är redan använd. Kontakta support.',
          )
        }
        return
      }

      if (success) {
        setAccountCreated(true)
        return
      }
      // This should never happen
      setError('något gick fel')
    },
    validateOnChange: true,

    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, 'Lösenordet måste vara minst 8 tecken')
        .required('Nödvändig'),
      passwordRepeat: Yup.string()
        .required('Nödvändig')
        .oneOf([Yup.ref('password')], 'Matchar inte det nya lösenordet'),
    }),
  })

  if (accountCreated) {
    return (
      <>
        <S2Bold>Skapa konto</S2Bold>
        <H5 data-testid='registrationFinished'>Ditt konto har skapats</H5>
        <Body1>
          Du kan nu logga in med din e-postadress och ditt lösenord.
        </Body1>
        <ButtonGreen
          style={{ width: '100%' }}
          onClick={() => history.push(`/login?loginType=${LoginType.PASSWORD}`)}
          data-testid='login-button'
        >
          Logga in
        </ButtonGreen>
      </>
    )
  }

  return (
    <>
      <SnackAlert {...snackbar} />
      <S2Bold data-testid='account-creation'>Skapa konto</S2Bold>
      <H5>Välj ett lösenord</H5>
      <Body1>
        För att slutföra registeringen av ditt konto måste du välja ett
        lösenord.
      </Body1>
      <Form
        onSubmit={handleSubmit}
        data-testid='password-login'
        style={{ width: '100%', gap: spacing.medium }}
      >
        <TextField
          type='password'
          name='password'
          label='Lösenord'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          variant='standard'
          placeholder='fyll i ditt Lösenord'
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          error={!!touched.password && !!errors.password}
          helperText={touched.password && errors.password}
          required
        />
        <TextField
          type='password'
          name='passwordRepeat'
          label='Upprepa lösenord'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.passwordRepeat}
          variant='standard'
          placeholder='fyll i ditt lösenord igen'
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          error={!!touched.passwordRepeat && !!errors.passwordRepeat}
          helperText={touched.passwordRepeat && errors.passwordRepeat}
          required
        />
        <ButtonGreen
          type='submit'
          disabled={isSubmitting || !isValid}
          data-testid='save'
        >
          spara
        </ButtonGreen>
      </Form>
    </>
  )
}
