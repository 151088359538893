import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { captureException } from '@sentry/browser'
import { Flex } from '../../Flex'
import { colors, spacing } from '../../../theme'
import { Body2, Body2Bold } from '../../Typography'
import {
  Category,
  CategoryDescriptiveStats,
  Feature,
  FilterInput,
  Role,
  SearchField,
  StringSearchType,
  useGetAggregatedDescriptiveStatsQuery,
  useMeQuery,
} from '../../../graphql/generated'
import { EmissionsData, getEmission } from './useCategoryPieData'
import { Loader } from '../../Loader'
import { RecatContext } from '../../../context/RecatContext'

const PieCategoryInfo = styled(Flex)<{ open: boolean }>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  border-top: ${(props) => (props.open ? '0.25px solid #b0b0b0' : 'none')};
  max-height: ${(props) => (props.open ? '100%' : '0')};
  overflow: hidden;
  padding: ${(props) => (props.open ? spacing.large : 0)};
  transition: all 150ms ease-in;
`

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.small};
`

const StatRow = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.small};
`

const StatText = styled(Body2)``

const StatPercentage = styled(Body2)`
  display: inline;
  margin-left: ${spacing.medium};
`

const LinkButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  text-align: left;
`

const InfoColumn = styled(Flex)`
  width: 50%;
  position: relative;
`

function LoaderOverlay(): React.JSX.Element {
  return (
    <Flex
      itemsCenter
      justifyCenter
      stretchWidth
      style={{
        position: 'absolute',
        zIndex: 10,
        height: '100%',
        background: 'rgb(255, 255, 255, 0.8)',
      }}
    >
      <Loader size={30} borderSize={5} />
    </Flex>
  )
}

function ErrorOverlay(): React.JSX.Element {
  return (
    <Flex
      itemsCenter
      justifyCenter
      stretchWidth
      style={{
        height: '100%',
        background: colors.white,
      }}
    >
      <Body2 data-testid='error-message'>Något gick fel</Body2>
    </Flex>
  )
}

function EmptyOverlay(): React.JSX.Element {
  return (
    <Flex
      itemsCenter
      justifyCenter
      stretchWidth
      style={{
        height: '100%',
        background: colors.white,
      }}
    >
      <Body2 data-testid='empty-message'>Inga data</Body2>
    </Flex>
  )
}

interface Props {
  showCategoryInfo: boolean
  selectedCategory: Category | undefined
  filter: FilterInput
  category: Category | undefined
  emissions: EmissionsData | undefined
  ghgp?: boolean
}

export function CategoryInfo({
  showCategoryInfo,
  selectedCategory,
  filter,
  category,
  emissions,
  ghgp,
}: Props): React.JSX.Element {
  const { setSearchCriteria, searchWithCriteria } = useContext(RecatContext)
  const history = useHistory()
  const { data: meData } = useMeQuery()
  const user = meData?.me

  const [categoryFilter, setCategoryFilter] = useState<number[]>([])

  const isEmptyCategory = () => {
    if (selectedCategory) {
      return !getEmission(selectedCategory.id, emissions?.emissions ?? [])
        ?.totalCO2e
    }
    return !emissions?.totalCO2e
  }

  const { data, loading, error, previousData } =
    useGetAggregatedDescriptiveStatsQuery({
      variables: {
        filter,
        // GHG-p never filters on categories, only scopes.
        categoryFilter: ghgp ? [] : categoryFilter,
      },
      skip: isEmptyCategory(),
    })

  const descriptiveStat:
    | _.Omit<CategoryDescriptiveStats, 'categoryId'>
    | undefined = loading
    ? previousData?.getAggregatedDescriptiveStats
    : data?.getAggregatedDescriptiveStats

  useEffect(() => {
    // The goal here is to set a list of categories for which we want to top emiters
    // We have three types of categories: the parent categories, the categories and the aggregated categories
    // The parent categories are 7 categories in which all other categories are grouped
    // The categories are the base division for the emissions
    // The aggregated categories are the "other" section when looking inside of a parent category.
    // They represent an aggregation of all the categories that are part of the parent category and have not been displayed yet.
    // If we want the top emiters for the parent category or a category we can just put the category id in the filter as they have their own stats
    // If we want the top emiters for an aggregated category we need to put the list of all the categories that are represented by it.
    const emissionArray = emissions?.emissions ?? []
    if (selectedCategory?.id && (!category || selectedCategory?.id !== 4)) {
      // We have selected a category and it is not an aggregated category (not other (id 4) or we are not inside a category so it is the parent category called other)
      setCategoryFilter([selectedCategory?.id])
    } else if (selectedCategory?.id === 4) {
      // the selected category is "others"  and we are inside a parent category
      // we need to filter on all the category aggregated under it

      const categories = getEmission(4, emissionArray)?.aggregated?.map(
        (e) => e.category.id,
      )
      setCategoryFilter(categories ?? [])
    } else if (category) {
      // We are inside a category and we have not selected any category
      if (!emissions?.history) {
        // we are inside one of the paretn category we can filter directly on the id
        setCategoryFilter([category.id])
      } else {
        // we are inside an "other" of a parent category, we need to get all the categories it contains
        // This means the one directly in it and the one aggregated in its other category
        const categories = emissionArray.flatMap((e) =>
          e.category.id !== 4
            ? e.category.id
            : (e.aggregated?.map((a) => a.category.id) ?? []),
        )
        setCategoryFilter(categories ?? [])
      }
    } else {
      // We are on the all category page, we want to aggregate on all the category so let the filter empty
      setCategoryFilter([])
    }
  }, [category, emissions, selectedCategory])

  useEffect(() => {
    if (error) {
      captureException(error)
    }
  }, [error])

  const searchTransac = (searchString: string, searchField: SearchField) => {
    const searchCriteria = [
      {
        id: 'tmp-id',
        searchField,
        searchString: [searchString],
        stringSearchType: StringSearchType.Equal,
      },
    ]
    setSearchCriteria(searchCriteria)
    searchWithCriteria(searchCriteria)
    history.push('/recat')
  }

  let titleText = selectedCategory?.name
  if (selectedCategory?.id === 4 && category)
    titleText = `${titleText} (inom ${category.name})`
  if (!titleText && category) {
    if (emissions?.history) {
      titleText = `Övrigt (inom ${category.name})`
    } else {
      titleText = category.name
    }
  }
  if (!titleText) {
    if (ghgp) {
      titleText = 'Alla scopes'
    } else {
      titleText = 'Alla kategorier'
    }
  }

  const descriptionText = selectedCategory
    ? `Här listas de leverantörer och bokföringskonton som står för mest utsläpp inom ${
        ghgp ? 'markerat scope' : 'den markerade kategorin'
      }.`
    : `Här listas de leverantörer och bokföringskonton som står för mest utsläpp inom ${titleText}`

  const getPercentageText = useCallback(
    (totalCO2e: number) => {
      const roundedValue = Math.round(
        (totalCO2e / (descriptiveStat?.totalCO2e ?? 1)) * 100,
      )
      if (roundedValue === 0) {
        return '< 1'
      }
      return roundedValue
    },
    [descriptiveStat?.totalCO2e],
  )

  const displayRecat =
    user?.role &&
    [Role.SysAdmin, Role.Admin].includes(user?.role) &&
    user?.organisation.enabledFeatures?.features?.includes(Feature.Transactions)

  return (
    <PieCategoryInfo open={showCategoryInfo} column>
      <Flex column stretchWidth>
        <Flex column>
          <Body2Bold>{titleText}</Body2Bold>
          <StatText style={{ marginTop: spacing.small }}>
            {descriptionText}
          </StatText>
        </Flex>
        <Flex
          stretchWidth
          style={{
            paddingTop: spacing.large,
          }}
        >
          <InfoColumn column itemsStretch style={{ paddingRight: spacing.xl }}>
            <TitleContainer>
              <Body2Bold>Leverantörer</Body2Bold>
              {/* <TooltipInfo info='Lista över leverantörer kopplade till de största andelarna utsläpp inom vald kategori.' /> */}
            </TitleContainer>
            {loading && <LoaderOverlay />}
            {error && <ErrorOverlay />}
            {isEmptyCategory() && <EmptyOverlay />}
            {(descriptiveStat?.supplierStats ?? []).map((s) => (
              <StatRow key={`${s.name}-${s.ownerId}-row`}>
                <StatText key={`${s.name}-name`} style={{ display: 'inline' }}>
                  {displayRecat ? (
                    <LinkButton
                      type='button'
                      onClick={() => {
                        if (s.ownerId) {
                          searchTransac(s.ownerId, SearchField.SupplierId)
                        } else {
                          searchTransac(s.name, SearchField.SupplierName)
                        }
                      }}
                    >
                      {s.name}
                    </LinkButton>
                  ) : (
                    s.name
                  )}
                </StatText>
                <StatPercentage
                  key={`${s.name}-percent`}
                  style={{ display: 'inline' }}
                >
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {getPercentageText(s.totalCO2e)} %
                  </span>
                </StatPercentage>
              </StatRow>
            ))}
          </InfoColumn>
          <InfoColumn
            column
            itemsStretch
            style={{
              paddingLeft: spacing.xl,
              borderLeftColor: 'lightgray',
              borderLeftWidth: 1,
              borderLeftStyle: 'solid',
            }}
          >
            <TitleContainer>
              <Body2Bold>Bokföringskonton</Body2Bold>
              {/* <TooltipInfo info='Lista över bokföringskonton kopplade till de största andelarna utsläpp inom vald kategori.' /> */}
            </TitleContainer>
            {loading && <LoaderOverlay />}
            {error && <ErrorOverlay />}
            {isEmptyCategory() && <EmptyOverlay />}
            {(descriptiveStat?.bookingAccountStats ?? []).map((s) => (
              <StatRow key={`${s.name}-row`}>
                <StatText key={`${s.name}-name`}>
                  {displayRecat ? (
                    <LinkButton
                      type='button'
                      onClick={() => {
                        if (s.ownerId) {
                          searchTransac(s.ownerId, SearchField.AccountId)
                        } else {
                          searchTransac(s.name, SearchField.AccountName)
                        }
                      }}
                      data-testid={`account-link-${s.name}`}
                    >
                      {s.name}
                    </LinkButton>
                  ) : (
                    s.name
                  )}
                </StatText>
                <StatPercentage key={`${s.name}-percent`}>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {getPercentageText(s.totalCO2e)} %
                  </span>
                </StatPercentage>
              </StatRow>
            ))}
          </InfoColumn>
        </Flex>
      </Flex>
    </PieCategoryInfo>
  )
}
