import React from 'react'
import { Svg, Path } from '@react-pdf/renderer'

/**
 * SVG using @react-pdf/renderer components: the Svalna logo, with black text.
 *
 * This is a conversion of the logo located in the `assets` folder.
 */
export function LogoWithDarkText(): React.JSX.Element {
  return (
    <Svg width='109' height='45' viewBox='0 0 109 45' fill='none'>
      <Path
        d='M49.56 27.18C52.245 27.18 53.565 25.8 53.565 23.97C53.565 20.235 47.82 21.3 47.82 19.725C47.82 19.125 48.33 18.735 49.23 18.735C50.25 18.735 51.33 19.08 52.14 19.83L53.325 18.24C52.335 17.325 51.015 16.845 49.425 16.845C47.07 16.845 45.63 18.225 45.63 19.89C45.63 23.655 51.39 22.44 51.39 24.18C51.39 24.765 50.835 25.29 49.65 25.29C48.27 25.29 47.175 24.66 46.47 23.925L45.3 25.59C46.245 26.535 47.625 27.18 49.56 27.18ZM61.4591 27L65.3141 16.995H62.8991L60.1241 24.675L57.3491 16.995H54.9341L58.7891 27H61.4591ZM75.8649 27L72.0099 16.995H69.3399L65.4849 27H67.8999L68.5299 25.305H72.8199L73.4499 27H75.8649ZM72.2349 23.43H69.1149L70.6749 19.125L72.2349 23.43ZM84.0738 27V25.125H79.8438V16.995H77.6988V27H84.0738ZM95.4636 27V16.995H93.3336V23.28L88.6986 16.995H86.5086V27H88.6386V20.475L93.4086 27H95.4636ZM107.766 27L103.911 16.995H101.241L97.3863 27H99.8013L100.431 25.305H104.721L105.351 27H107.766ZM104.136 23.43H101.016L102.576 19.125L104.136 23.43Z'
        fill='black'
      />
      <Path
        d='M38.8423 6.39522L39.2714 3.55798L42.8377 6.19941L38.8423 6.39522Z'
        fill='#519032'
      />
      <Path
        d='M39.2714 3.58716L30.2888 6.46189L37.684 16.0985L39.2714 3.58716Z'
        fill='#67AE3E'
      />
      <Path
        d='M30.2889 6.49109L28.2682 9.93244L37.6882 16.1069L30.2889 6.49109Z'
        fill='#519032'
      />
      <Path
        d='M30.293 6.4869L21.0189 2.24146L21.9396 5.83279L28.2682 9.93241L30.293 6.4869Z'
        fill='#67AE3E'
      />
      <Path
        d='M21.9438 5.82446L9.31164 14.2028L2.1748 11.299L21.0189 2.24146L21.9438 5.82446Z'
        fill='#519032'
      />
      <Path
        d='M21.9438 5.82446L23.6395 20.2731L30.0139 22.9479L37.7048 16.1068L21.9438 5.82446Z'
        fill='#8AC640'
      />
      <Path
        d='M21.9438 5.82446L2.40396 18.7816L23.6395 20.2731L21.9438 5.82446Z'
        fill='#67AE3E'
      />
      <Path
        d='M30.0347 22.9312L17.6234 30.4014L23.5895 20.2231L30.0347 22.9312Z'
        fill='#519032'
      />
      <Path
        d='M30.0139 22.9478L19.9356 42.8293L17.6234 30.4013L30.0139 22.9478Z'
        fill='#67AE3E'
      />
    </Svg>
  )
}
