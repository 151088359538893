import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from '@mui/material'
import { LoginPageWrapper } from '../components/LoginPageWrapper'
import { ButtonLightBorder } from '../../../components/Buttons'
import { RegistrationForm } from './components/RegistrationForm'
import { Flex } from '../../../components/Flex'
import { CanRegisterPage } from './components/MatchingPage'
import { CannotRegisterPage } from './components/NotMatchingPage'
import { ThanksPage } from './components/ThanksPage'
import { SorryToHear } from './components/SorryToHear'
import { useRemoveOrgRegistrationMutation } from '../../../graphql/generated'
import { spacing } from '../../../theme'
import { AccountCreation } from './AccountCreation'

function RedirectToLogin() {
  const history = useHistory()
  return (
    <Flex
      column
      itemsCenter
      style={{
        width: '80%',
        gap: spacing.medium,
        marginTop: spacing.medium,
      }}
    >
      <span>Har du redan ett konto?</span>
      <ButtonLightBorder
        style={{ width: '100%' }}
        onClick={() => history.push('/login')}
      >
        Logga in
      </ButtonLightBorder>
    </Flex>
  )
}

function RedirectToLanding() {
  const history = useHistory()
  return (
    <Flex
      column
      itemsCenter
      style={{
        width: '80%',
        gap: spacing.medium,
        marginTop: spacing.medium,
      }}
    >
      <Button onClick={() => history.push('/')} color='secondary'>
        Tillbaka till startsidan
      </Button>
    </Flex>
  )
}

export enum RegistrationViews {
  form,
  canRegister,
  cannotRegister,
  thanks,
  sorryToHear,
  accountCreation,
}
export function RegistrationPage(): React.JSX.Element {
  const [error, setError] = React.useState('')
  const [view, setView] = React.useState(RegistrationViews.form)
  const [registrationId, setRegistrationId] = React.useState('')
  const [confirmationCode, setConfirmationCode] = React.useState('')
  const [removeRegistration] = useRemoveOrgRegistrationMutation()

  const location = useLocation()

  const [registrationCode, setRegistrationCode] = React.useState<string | null>(
    null,
  )
  const [userCode, setUserCode] = React.useState<string>('')

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get('id')
    const deletionConfirmationCode = queryParams.get('deletionConfirmationCode')
    if (id && deletionConfirmationCode) {
      removeRegistration({
        variables: { id: id, confirmationCode: deletionConfirmationCode },
      }).then(() => {
        setView(RegistrationViews.sorryToHear)
      })
    }
    const regCode = queryParams.get('registrationCode')
    setRegistrationCode(regCode)
    const queryUserCode = queryParams.get('userCode')
    setUserCode(queryUserCode ?? '')
    if (regCode || queryUserCode) {
      setView(RegistrationViews.accountCreation)
    }
  }, [location.search, removeRegistration])

  switch (view) {
    case RegistrationViews.canRegister:
      return (
        <LoginPageWrapper error={error} redirectBlock={<RedirectToLanding />}>
          <CanRegisterPage />
        </LoginPageWrapper>
      )
    case RegistrationViews.cannotRegister:
      return (
        <LoginPageWrapper error={error} redirectBlock={<RedirectToLanding />}>
          <CannotRegisterPage
            setError={setError}
            setView={setView}
            registrationCode={registrationId}
            confirmationCode={confirmationCode}
          />
        </LoginPageWrapper>
      )
    case RegistrationViews.thanks:
      return (
        <LoginPageWrapper error={error} redirectBlock={<RedirectToLanding />}>
          <ThanksPage />
        </LoginPageWrapper>
      )
    case RegistrationViews.sorryToHear:
      return (
        <LoginPageWrapper error={error} redirectBlock={<RedirectToLanding />}>
          <SorryToHear />
        </LoginPageWrapper>
      )
    case RegistrationViews.accountCreation:
      return (
        <LoginPageWrapper error={error} redirectBlock={<RedirectToLanding />}>
          <AccountCreation
            registrationCode={registrationCode ?? ''}
            userCode={userCode}
          />
        </LoginPageWrapper>
      )
    default:
      return (
        <LoginPageWrapper error={error} redirectBlock={<RedirectToLogin />}>
          <RegistrationForm
            setError={setError}
            setView={setView}
            setRegistrationCode={setRegistrationId}
            setConfirmationCode={setConfirmationCode}
          />
        </LoginPageWrapper>
      )
  }
}
