import React, { useContext, useState } from 'react'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Stack } from '@mui/material'
import { Flex } from '../../Flex'
import {
  Category,
  Job,
  RecatRuleInfoFragment,
  useDeleteRecatRulesMutation,
} from '../../../graphql/generated'
import { ButtonLightBorder } from '../../Buttons'
import { JobContext } from '../../../context/JobContext'
import { spacing } from '../../../theme'
import { SnackAlert } from '../../SnackAlert'

interface Props {
  rulesRows: Array<RecatRuleInfoFragment & { dateFilter: string }>
}

export function RuleTable({ rulesRows }: Props): React.JSX.Element {
  const { jobRunning, setJobRunning, handleJob } = useContext(JobContext)
  const [selectedRules, setSelectedRules] = useState<string[]>([])

  const [deleteRecatRules] = useDeleteRecatRulesMutation()

  const [error, setError] = useState('')

  const deleteRules = async () => {
    try {
      const result = await deleteRecatRules({
        variables: {
          rules:
            rulesRows
              ?.filter((rule) => selectedRules.includes(rule.id))
              .map((row) => ({
                organisationId: '',
                id: row.id,
                applied: row.applied,
              })) ?? [],
        },
      })

      if (result.data?.deleteRecatRules) {
        handleJob(result.data?.deleteRecatRules as Job)
      } else if (result.errors) {
        setError('Ett fel inträffade när regeln skulle raderas')
        setJobRunning(false)
      }
    } catch (err) {
      setError('Ett fel inträffade när regeln skulle raderas')
      setJobRunning(false)
    }
  }

  const renderCell = (params: GridRenderCellParams) => {
    if (params.value?.length) {
      return (
        <Flex column>
          {params.value.map((value: string) => {
            return <div key={value}>{value}</div>
          })}
        </Flex>
      )
    }
    return 'alla'
  }

  const rulesColumns: GridColDef[] = [
    {
      field: 'suppliers',
      headerName: 'Leverantör',
      flex: 1,
      renderCell,
    },
    {
      field: 'accounts',
      headerName: 'Konto',
      flex: 1,
      renderCell,
    },
    {
      field: 'commentRegexp',
      headerName: 'Kommentar-regexp',
      flex: 1,
      valueFormatter: (value: string) => {
        if (!value) {
          return 'alla'
        }
        return value
      },
    },
    {
      field: 'dateFilter',
      headerName: 'Datum',
      flex: 1,
      valueFormatter: (value: string) => {
        if (!value) {
          return 'alla'
        }
        return value
      },
    },
    {
      field: 'comment',
      headerName: 'Kommentar',
      flex: 1,
    },
    {
      field: 'category',
      headerName: 'Kategori',
      flex: 1,
      valueFormatter: (value: Category) => {
        return value?.name
      },
    },
    {
      field: 'emissionIntensity',
      headerName: 'Intensitet',
      flex: 1,
      valueFormatter: (value: number) => {
        if (value !== undefined) {
          return value?.toFixed(5)
        }
        return ''
      },
    },
    // TODO RECAT put this commented out section back when the new emission model is published
    // {
    //   field: 'emissionLayers',
    //   headerName: 'utsläppsskikt',
    //   flex: 1,
    //   renderCell: (params: GridRenderCellParams) => {
    //     if (params.value) {
    //       return (
    //         <Flex column>
    //           {Object.entries(params.value).map(([key, value]) => {
    //             if (value && layerKeys.includes(key)) {
    //               return <div key={key}>{layerKeyToName(key)}</div>
    //             }
    //             return undefined
    //           })}
    //         </Flex>
    //       )
    //     }
    //     return ''
    //   },
    // },
  ]

  function noRow() {
    return (
      <Stack height='100%' alignItems='center' justifyContent='center'>
        Finns inga regler
      </Stack>
    )
  }

  return (
    <Flex column stretchWidth style={{ gap: spacing.medium }}>
      <SnackAlert open={!!error} severity='error' onClose={() => setError('')}>
        {error}
      </SnackAlert>
      <ButtonLightBorder
        disabled={jobRunning || selectedRules?.length === 0}
        onClick={deleteRules}
        startIcon={<DeleteOutlineIcon />}
        data-testid='delete-button'
        style={{ alignSelf: 'end' }}
      >
        <span style={{ position: 'relative', top: '1.5px' }}>
          Radera valda regler
        </span>
      </ButtonLightBorder>
      <Flex stretchWidth>
        <DataGrid
          style={!rulesRows?.length ? { height: '200px' } : {}}
          rows={rulesRows ?? []}
          columns={rulesColumns}
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            setSelectedRules(ids as string[])
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          getRowHeight={() => 'auto'}
          slots={{
            noRowsOverlay: noRow,
          }}
        />
      </Flex>
    </Flex>
  )
}
