import React from 'react'
import { styled } from 'styled-components'
import { Layout } from '../../components/Layout'
import { Flex } from '../../components/Flex'
import { LayoutContent } from '../../components/LayoutContent'
import { GhgpPieCard } from '../../components/ghgp/GhgpPieCard'
import { gargantuanGap, spacing } from '../../theme'
import { Body1, H6Bold } from '../../components/Typography'
import ghgpImg from '../../assets/images/GHGP.svg'

/**
 * Alt-text for the GHGP scopes image.
 *
 * Note: WAI recommends not using images containing text, except for logos.
 * But, since we are using an image: the image in informative and contains text, so
 * the WAI recommendation is to describe the contents of the image, and include all the text.
 */
const ghgpImageAltText = `Bilden visar en illustration över indelningen i GHGP-scopes relativt den rapporterande verksamheten.

Indelningen som visas innehåller samma information som ovan paragrafer,
men med en mer detaljerad lista av exempel för varje scope, vilka följer här:

1. Inkluderade utsläpp

1.a. Uppströms

Scope 3 (indirekta utsläpp):
* Leasade tillgångar
* Varor & tjänster
* Kapitalvaror
* Bränsle & energi
* Tjänsteresor
* Avfall från verksamheten
* Transport & distribution

Scope 2 (indirekta utsläpp):
* Inköp av el, ånga, värme eller kyla

1.b. Rapporterande verksamhet

Scope 1 (direkta utsläpp):
* Brännande av fossila bränslen

2. Icke inkluderade utsläpp

2.a. Uppströms

Scope 3 (indirekta utsläpp)
* Anställdas pendling

2.b. Nedströms

Scope 3 (indirekta utsläpp)
* Transport & distribution
* Användning av såld produkt
* Bearbetning av såld produkt
* Sluthantering av såld produkt
* Investeringar
* Franchising
`

const GhgpImage = styled.img`
  width: 100%;
`

const Ul = styled.ul`
  margin: 0;
  padding-left: ${spacing.large};
`

export function GhgpPage(): React.JSX.Element {
  return (
    <Layout
      // Title text uses a non-breaking hyphen, as it seems a regular hyphen will result
      // in the text being displayed as two lines.
      title={`GHG\u2011scopes`}
    >
      <LayoutContent
        // Huge gap between graph / text block / image.
        style={{ gap: gargantuanGap }}
      >
        <Flex column stretchWidth>
          <GhgpPieCard />
        </Flex>
        <Flex style={{ flexDirection: 'column', gap: spacing.xxl }}>
          <Flex column stretchWidth style={{ gap: spacing.small }}>
            <H6Bold>Vilka utsläpp inkluderas i beräkningarna ovan?</H6Bold>
            <Body1>
              I beräkningarna inkluderas Scope 1, Scope 2 och alla uppströms
              Scope 3-utsläpp, med undantag för anställdas pendling. Detta
              innebär att vi täcker in:
            </Body1>
            <Ul>
              <li>
                <Body1>
                  Scope 1-utsläpp: Direkta utsläpp av växthusgaser från källor
                  som kontrolleras eller ägs av organisationen, till exempel
                  förbränning av bränsle i egna pannor, ugnar och fordon.
                </Body1>
              </li>
              <li>
                <Body1>
                  Scope 2-utsläpp: Indirekta utsläpp från inköp av el, ånga,
                  värme eller kyla. Dessa utsläpp sker hos energileverantören
                  men redovisas av organisationen eftersom de är ett resultat av
                  dess energianvändning.
                </Body1>
              </li>
              <li>
                <Body1>
                  Uppströms Scope 3-utsläpp: Indirekta utsläpp som uppstår i
                  leverantörskedjan före organisationens egen verksamhet, såsom
                  produktion och transport av inköpta varor och tjänster,
                  avfallshantering och affärsresor.
                </Body1>
              </li>
            </Ul>
          </Flex>
          <Flex column stretchWidth style={{ gap: spacing.small }}>
            <H6Bold>
              Vilka Scope 3 utsläppskällor inkluderas inte i beräkningarna?
            </H6Bold>
            <Body1>Följande utsläpp ingår inte i beräkningarna:</Body1>
            <Ul>
              <li>
                <Body1>
                  Anställdas pendling: Utsläpp från medarbetares resor mellan
                  hem och arbetsplats.
                </Body1>
              </li>
              <li>
                <Body1>
                  Nedströms Scope 3-utsläpp: Indirekta utsläpp som uppstår efter
                  organisationens egen verksamhet, såsom användning av sålda
                  produkter, sluthantering av sålda produkter (end-of-life
                  treatment), nedströms transporter och distribution som ej
                  bekostas av organisationen, bearbetning av sålda produkter,
                  investeringar och franchising.
                </Body1>
              </li>
            </Ul>
          </Flex>
          <Flex column stretchWidth style={{ gap: spacing.small }}>
            <H6Bold>Vad är Greenhouse Gas Protocol?</H6Bold>
            <Body1>
              Greenhouse Gas Protocol (GHG Protocol) är en internationellt
              erkänd standard utvecklad av World Resource Institute (WRI) för
              att mäta växthusgasutsläpp på ett enhetligt sätt. Protokollet
              erbjuder riktlinjer för hur olika organisationer kan beräkna och
              rapportera sina utsläpp inom Scope 1, Scope 2 och Scope 3. Detta
              underlättar jämförbarhet och transparens i klimatrapporteringen
              och hjälper organisationer att identifiera områden där de kan
              minska sin klimatpåverkan.
            </Body1>
          </Flex>
        </Flex>
        <Flex
          column
          style={{
            width: '90%',
            alignSelf: 'center',
            gap: spacing.small,
          }}
        >
          <GhgpImage src={ghgpImg} alt={ghgpImageAltText} />
          <Body1>
            Bilden visar vad som innefattas av Scope 1-3, och var de uppstår i
            leverantörskedjan. De gråa områdena (anställdas pendling inom Scope
            3 uppströms, och hela Scope 3 nedströms) är inte inkluderade i
            Svalnas beräkningar.
          </Body1>
        </Flex>
      </LayoutContent>
    </Layout>
  )
}
