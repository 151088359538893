const redirectURI = `${window.origin}/fortnox`
const scope = encodeURIComponent(
  `supplierinvoice bookkeeping payment supplier companyinformation profile`,
)

const fortnoxURL = `https://apps.fortnox.se/oauth-v1/auth?client_id=${process.env.REACT_APP_FORTNOX_CLIENT_ID}&redirect_uri=${redirectURI}&scope=${scope}&access_type=offline&response_type=code`

/**
 * Redirect user to Fortnox for their login flow.
 * At the end of it, the user will be redirected back to CIS
 * with various query params, including a state we pass them.
 *
 * @param registrationCode Registration code from CIS server
 * @param flag Type of registration: o = org , u = user ;
 *        This determines which mutation is used to renew the code
 *        if needed.
 */
export const goToFortnox = (
  registrationCode?: string | null,
  flag?: 'o' | 'u',
): void => {
  /*
   * The state is here to CSRF attacks. We need to save it to check
   * that the state retruned in the "response" is the same
   */
  let state = Math.random().toString(36).slice(2)
  localStorage.setItem('fortnox_state', state)

  // add the registration id to the state if there is one so that we can get it back
  // when comming back from fortnox
  if (registrationCode) {
    state = `${state}regCode${registrationCode}${flag}`
  }

  window.location.assign(`${fortnoxURL}&state=${state}`)
}

/**
 * When the user returns from going to fortnox with the function above, we get the state set above from the url
 * @param stateString the state contained in the url
 * @returns {
 *            state: the state for CSRF attacks,
 *            registrationCode: the registration from the CIS server,
 *            flag: the flag set to know which type of code it is:  o = org , u = user ;
 *           }
 */
export const parseFortnoxState = (
  stateString: string,
): { state: string; registrationCode?: string; flag?: string } => {
  const [state, code] = stateString.split('regCode')
  let registrationCode
  let flag
  if (code) {
    registrationCode = code.slice(0, -1)
    flag = code.slice(-1)
  }
  return {
    state,
    registrationCode,
    flag,
  }
}
