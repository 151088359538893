import { useFormik } from 'formik'
import React, { useState } from 'react'
import { TextField } from '@mui/material'
import * as Yup from 'yup'
import { ButtonGreen } from '../../components/Buttons'
import { useChangePasswordMutation } from '../../graphql/generated'
import { toErrorMap } from '../../utils/toErrorMap'
import { Form } from '../authentication/components/Form'
import { Flex, FlexingBox, HeadRow } from '../../components/Flex'
import { TitleWithSub } from '../../components/TitleWithSub'
import { spacing } from '../../theme'
import { SnackAlert } from '../../components/SnackAlert'

export function ChangePassword(): React.JSX.Element {
  const [changePassword] = useChangePasswordMutation()
  const [successMessage, setSuccessMessage] = useState('')

  const passwordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, 'Lösenordet måste vara minst 8 tecken')
      .required('Nödvändig'),
    newPassword2: Yup.string()
      .required('Nödvändig')
      .oneOf([Yup.ref('newPassword')], 'Matchar inte det nya lösenordet'),
  })

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
    },
    validationSchema: passwordSchema,
    onSubmit: async (variables, { setErrors, resetForm }) => {
      const { data } = await changePassword({
        variables: {
          oldPassword: variables.oldPassword,
          newPassword: variables.newPassword,
        },
      })

      const errors = data?.changePassword.errors
      if (errors) {
        setErrors(toErrorMap(errors))
      } else {
        setSuccessMessage('Bytte lösenord')
        resetForm()
      }
    },
  })

  return (
    <FlexingBox>
      <SnackAlert
        open={!!successMessage}
        severity='success'
        onClose={() => setSuccessMessage('')}
      >
        {successMessage}
      </SnackAlert>
      <HeadRow>
        <TitleWithSub title='Ändra lösenord' sub='' />
      </HeadRow>
      <Flex stretchWidth justifyCenter style={{ padding: spacing.medium }}>
        <Form onSubmit={formik.handleSubmit} style={{ gap: spacing.medium }}>
          <TextField
            type='password'
            name='oldPassword'
            label='Befintligt lösenord'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant='standard'
            error={!!formik.touched.oldPassword && !!formik.errors.oldPassword}
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
            required
            value={formik.values.oldPassword}
          />
          <TextField
            type='password'
            name='newPassword'
            label='Nytt lösenord'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant='standard'
            error={!!formik.touched.newPassword && !!formik.errors.newPassword}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            required
            value={formik.values.newPassword}
          />
          <TextField
            type='password'
            name='newPassword2'
            label='Upprepa nytt lösenord'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant='standard'
            error={
              !!formik.touched.newPassword2 && !!formik.errors.newPassword2
            }
            helperText={
              formik.touched.newPassword2 && formik.errors.newPassword2
            }
            required
            value={formik.values.newPassword2}
          />
          <ButtonGreen type='submit'>Byt lösenord</ButtonGreen>
        </Form>
      </Flex>
    </FlexingBox>
  )
}
