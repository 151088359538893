import { ApolloProvider } from '@apollo/client'
import React, { useContext } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import './utils/date' // so that dayjs get initialized
import { client } from './apollo/apolloClient'
import { UnauthorizedRoutes } from './AppRoutes'
import { ErrorBoundary } from './components/error-boundry/ErrorBoundary'
import { UserContext, UserProvider } from './context/UserContext'
import { theme } from './theme'
import { appHistory } from './utils/history'
import { LogoutPage } from './routes/authentication/Logout'
import { AuthorizedApp } from './AuthorizedApp'

function App(): React.JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <UserProvider>
            <Router history={appHistory}>
              <AppController />
            </Router>
          </UserProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export function AppController(): React.JSX.Element {
  const { isLoggedIn } = useContext(UserContext)

  return (
    <Switch>
      {/* We put the logout route here separately because it needs to be available regardless of whether 
        the user is logged in, but it must also not depend on the AppDataContext, since it will get stuck 
        in a loading state if the access token is no longer valid. */}
      <Route exact path='/logout' component={LogoutPage} />
      {isLoggedIn ? (
        <Route path='/' component={AuthorizedApp} />
      ) : (
        <Route path='/' component={UnauthorizedRoutes} />
      )}
      <Redirect to='/' />
    </Switch>
  )
}

export default App
