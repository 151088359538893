import React, { useMemo } from 'react'
import { Document, DocumentProps, Page, View, Text } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import _ from 'lodash'
import type { CategroyPieData } from '../../charts/category-pie/useCategoryPieData'
import { BackgroundSvg } from './svg/BackgroundSvg'
import { PieForPdf } from './PieForPdf'
import { LogoWithDarkText } from './svg/LogoWithDarkText'
import { OrganisationFragment } from '../../../graphql/generated'
import { formatISO } from '../../../utils/date'
import { getNumberFormatter } from '../../../utils/adaptiveNumberFormat'
import { IFilterDatePeriod } from '../../../utils/datePeriod'
import { ghgpCertStyle } from './pdfStyles'

type Props = DocumentProps & {
  pieData?: CategroyPieData
  organisation: OrganisationFragment
  selectedPeriod: IFilterDatePeriod
}

export function GhgpDoc(props: Props): React.JSX.Element {
  const { pieData, organisation, selectedPeriod, ...pdfProps } = props

  const scopeModified: { [scope: number]: boolean } = useMemo(() => {
    const byGhgpScope = _.groupBy(
      pieData?.data?.emissions ?? [],
      (e) => e.category.id, // "category" is ghgp scope.
    )
    return _.mapValues(
      byGhgpScope,
      (es) =>
        // Determine if at least one emission in this scope has been modified
        // by customer-made rules.
        !!es.find(
          (e) =>
            e.intensityModifiedByCustomerRule || e.sniModifiedByCustomerRule,
        ),
    )
  }, [pieData?.data?.emissions])

  // Check if at least one scope is modified,
  // in which case we display a disclaimer text.
  const userModified = _.reduce(
    scopeModified,
    (res, scopeMod) => res || scopeMod,
  )

  // The period name, e.g. "2023" or "2024 (jan - maj)", should be
  // displayed over the pie chart.
  // `partialPeriodText` is either undefined, or e.g. "jan - maj".
  // If this is not a partial year, the postfix must be empty
  // (not e.g. a single space) to ensure the text properly centres.
  const periodPostfix =
    selectedPeriod.partialPeriodText?.replace(/^/, ' (').concat(')') ?? ''
  const periodName = `${selectedPeriod.name}${periodPostfix}`

  // With standard settings on A4 (with 72 ppi), the size of a page is:
  //   width: 595 px
  //   height: 842 px
  // When using 'pt' units for styles in the document, or just plain numbers,
  // they follow the same scale, i.e. the width is still 595 in 'pt' units,
  // which is the default unit used.
  return (
    <Document {...(pdfProps ?? {})}>
      <Page size='A4' orientation='portrait' style={ghgpCertStyle.page}>
        {/* Background image */}
        <View fixed style={ghgpCertStyle.backgroundImageContainer}>
          <BackgroundSvg />
        </View>
        {/* Page content parts */}
        <View style={ghgpCertStyle.pageContainer}>
          {/* Page header */}
          <View style={ghgpCertStyle.pageHeader}>
            {/* Top left: Date stamp */}
            <View style={ghgpCertStyle.dateStamp}>
              <Text style={ghgpCertStyle.dateStampText}>
                {formatISO(dayjs.utc())}
              </Text>
            </View>
            {/* Right side: Logo and "created by" text */}
            <View style={ghgpCertStyle.pageHeaderRight}>
              <View style={ghgpCertStyle.logoContainer}>
                <LogoWithDarkText />
              </View>
              <View style={ghgpCertStyle.createdByContainer}>
                <Text style={ghgpCertStyle.createdByText}>Framtaget av</Text>
                <Text style={ghgpCertStyle.createdByText}>
                  Svalna AB, Göteborg
                </Text>
              </View>
            </View>
          </View>
          {/* Spacing between page header and content header */}
          <View style={ghgpCertStyle.spacer16} />
          {/* Content header */}
          <View style={ghgpCertStyle.contentHeader}>
            <Text style={ghgpCertStyle.certHeading}>UTSLÄPPSINTYG</Text>
            <Text style={ghgpCertStyle.certScopeHeading}>
              MÄTNING AV SCOPE 1-3
            </Text>
          </View>
          {/* Spacing between content header and company name */}
          <View style={ghgpCertStyle.spacer16} />
          {/* Graph header */}
          <View style={ghgpCertStyle.graphHeader}>
            <Text>{organisation.name}</Text>
          </View>
          {/* Spacing between company name and graph */}
          <View style={ghgpCertStyle.spacer8} />
          <View style={ghgpCertStyle.dividerLine} />
          {/* Graph and table content */}
          <View style={ghgpCertStyle.mainContentContainer}>
            {/* Pie on left side */}
            <View style={ghgpCertStyle.pieContainer}>
              {/* Smaller container to vertically center the pie */}
              <View style={ghgpCertStyle.pieAnchor}>
                <PieForPdf pieData={pieData} />
                <View style={ghgpCertStyle.piePeriodTextContainer}>
                  <Text style={ghgpCertStyle.piePeriodText}>{periodName}</Text>
                </View>
              </View>
            </View>
            {/* Table on right side */}
            <View style={ghgpCertStyle.tableContainer}>
              <ScopeTable pieData={pieData} scopeModified={scopeModified} />
            </View>
          </View>
          {/* Graph footer: description / disclaimer texts */}
          <View style={ghgpCertStyle.graphDescriptionFooter}>
            <Text>
              Utsläppen representerar uppströms utsläpp från Scope 1 - 3.
            </Text>
            {!!userModified && (
              <Text>
                *Företaget har själva ändrat värdet utan att Svalna granskat.
              </Text>
            )}
          </View>
          {/* Separator line with padding above/below */}
          <View style={ghgpCertStyle.spacer8} />
          <View style={ghgpCertStyle.dividerLine} />
          <View style={ghgpCertStyle.spacer8} />
          {/* Footer content */}
          <View style={ghgpCertStyle.pageFooterContainer}>
            {/* Paragraphs */}
            <View style={ghgpCertStyle.pageFooterParagraphContainer}>
              <View>
                <Text>
                  Genom att låta Svalna analysera bokföringsdata och
                  sammanställa information från olika register har{' '}
                  {organisation.name} fått sina klimatutsläpp uppskattade.
                  Utsläppen är uppdelade på Scope 1-3 i enlighet med Greenhouse
                  Gas Protocol. Svalnas modell analyserar och kategoriserar all
                  data automatiskt, vilket frigör tid för {organisation.name}{' '}
                  att fokusera på konkreta åtgärder för att minska sin
                  klimatpåverkan. Systemet ger en tydlig överblick av utsläppen
                  och möjliggör effektiv uppföljning samt transparent
                  kommunikation kring organisationens framsteg.
                </Text>
              </View>
              <View>
                <Text>
                  Detta intyg är ett erkännande av det engagemang i
                  omställningen mot en hållbar framtid som {organisation.name}{' '}
                  uppvisar. Tillsammans gör vi skillnad!
                </Text>
              </View>
            </View>
            {/* "Signature" */}
            <View>
              <Text style={ghgpCertStyle.ceoSignText}>David Andersson, VD</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

function ScopeTable({
  pieData,
  scopeModified,
}: {
  pieData?: CategroyPieData
  scopeModified: { [scope: number]: boolean }
}) {
  return (
    <View style={ghgpCertStyle.scopeTable}>
      {/* Header row */}
      <View style={ghgpCertStyle.stRow}>
        {/* Empty header cell for icon */}
        <View style={ghgpCertStyle.stIconCell} />
        <View style={ghgpCertStyle.stScopeCell}>
          <Text>SCOPE</Text>
        </View>
        <View style={ghgpCertStyle.stEmissionCell}>
          <Text>UTSLÄPP</Text>
        </View>
        <View style={ghgpCertStyle.stPercentCell}>
          <Text>%</Text>
        </View>
      </View>
      {pieData?.data?.emissions.map((e) => {
        // Note: This data is ghgp scope data,
        //       so "categories" are actually scopes;
        //       the numeric id is the scope number, etc.
        const modifiedByUser = !!scopeModified[e.category.id]
        return (
          <View key={e.category.id} style={ghgpCertStyle.stRow}>
            <View
              style={[
                ghgpCertStyle.stIconCell,
                {
                  color: e.category.color,
                },
              ]}
            >
              <View
                style={[
                  ghgpCertStyle.stIconCircle,
                  { backgroundColor: e.category.color },
                ]}
              />
            </View>
            <View style={ghgpCertStyle.stScopeCell}>
              <Text>
                {e.category.name}
                {/*
                  Asterisk next to scope name if there are emissions
                  which have been modified by customer-made rules
                */}
                {modifiedByUser ? '*' : ''}
              </Text>
            </View>
            <View style={ghgpCertStyle.stEmissionCell}>
              <Text>
                {getNumberFormatter([e.totalCO2e]).format(e.totalCO2e)}
              </Text>
            </View>
            <View style={ghgpCertStyle.stPercentCell}>
              <Text>{e.percent}%</Text>
            </View>
          </View>
        )
      })}
    </View>
  )
}
