import _ from 'lodash'
import { useMemo } from 'react'
import { FilterInput, Period, Aggregation } from '../../graphql/generated'
import { useEmissionData } from '../../hooks/useEmissionData'
import {
  CategroyPieData,
  EmissionData,
} from '../charts/category-pie/useCategoryPieData'
import { colors } from '../../theme'

function getGhgpScopeColour(scopeId: number): string {
  if (scopeId === 1) {
    return colors.ghgpScope1
  } else if (scopeId === 2) {
    return colors.ghgpScopeIndigo
  } else if (scopeId === 3) {
    return colors.ghgpScope3
  }
  // Should never happen.
  throw new Error('Okänt GHGp-scope')
}

type GhgpProps = {
  filter: FilterInput
  period: Period
  // TODO: If we add "dig down" on scopes, then similar to `useCategoryPieData`,
  //       we must have a list of scopes to filter for sub-scopes.
}

/**
 * Get `CategroyPieData`, where the emission category id is instead the GHGp scope.
 *
 * The data is sorted ascending by scope.
 *
 * The three major scopes (1,2,3) are always included, but can be zero.
 * (This is subject to change if we add sub-scopes, and want to dig into the data).
 */
export function useGhgpData({ filter, period }: GhgpProps): CategroyPieData {
  const { data, loading, error } = useEmissionData({
    filter,
    period,
    aggregations: [Aggregation.GhgpScope],
  })

  return useMemo<CategroyPieData>(() => {
    // If loading, but data has length, the data is the "previous data",
    // which useEmissionData handles internally, and it should be processed.
    if (error || (loading && !data.length)) {
      return {
        error,
        loading,
        data: undefined,
      }
    }
    // Sum the emissions, and at the same time track which
    // main scopes are present.
    // Any missing scopes should be filled back with zero emissions.
    let totalCO2e: number = 0
    const scopes = new Set<number>()
    data.forEach((e) => {
      totalCO2e += e.totalCO2e
      scopes.add(Number.parseInt(e.aggregationKeys[0], 10))
    })

    // In order to re-use the category pie chart, pretend the scopes
    // are categories.
    const es = data.map<EmissionData>((e) => {
      const ghgpScope = Number.parseInt(e.aggregationKeys[0], 10)
      return {
        category: {
          id: ghgpScope,
          children: [],
          name: `Scope ${ghgpScope}`,
          color: getGhgpScopeColour(ghgpScope),
        },
        percent: ((e.totalCO2e / totalCO2e) * 100).toFixed(0),
        period: e.period,
        totalCO2e: e.totalCO2e,
        intensityModifiedByCustomerRule:
          e.intensityModifiedByCustomerRule ?? undefined, // GQL type null -> undef.
        sniModifiedByCustomerRule: e.sniModifiedByCustomerRule ?? undefined,
      }
    })

    // Fill any missing scopes
    ;[1, 2, 3].forEach((mainScope) => {
      if (!scopes.has(mainScope)) {
        es.push({
          category: {
            id: mainScope,
            children: [],
            name: `Scope ${mainScope}`,
            color: getGhgpScopeColour(mainScope),
          },
          percent: '0',
          period: '',
          totalCO2e: 0,
        })
      }
    })

    // Sort in ascending order, so the table will always display
    // in the order of scope 1, 2, 3.
    const sortedEs = _.sortBy(es, (e) => e.category.id)

    return {
      loading,
      error,
      data: {
        totalCO2e,
        emissions: sortedEs,
      },
    }
  }, [error, loading, data])
}
