import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
} from '@mui/x-data-grid'
import SaveIcon from '@mui/icons-material/Save'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Close'
import RsvpIcon from '@mui/icons-material/Rsvp'
import { AlertProps, TextField, Tooltip } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { captureException } from '@sentry/browser'
import { Section, SectionId, SysadminSectionId } from '../../emission/Section'
import { Flex, FlexingBox, HeadRow } from '../../../components/Flex'
import { TitleWithSub } from '../../../components/TitleWithSub'
import {
  GetRegistrationsDocument,
  OrgRegistration,
  useApproveOrgRegistrationMutation,
  useGetRegistrationsQuery,
  useRegisterOrganisationMutation,
  useUpdateOrgRegistrationApprovalCodeMutation,
} from '../../../graphql/generated'
import { ButtonGreen } from '../../../components/Buttons'
import { toErrorMap } from '../../../utils/toErrorMap'
import { LinkSentPopup } from './LinkSentPopup'
import { spacing } from '../../../theme'
import { SnackAlert } from '../../../components/SnackAlert'

interface Props {
  active: {
    scroll: boolean
    sectionId: SectionId
  }
  handleSectionVisibilityChange: (
    sectionId: SectionId,
    isVisible: boolean,
  ) => void
}

export function Registrations({
  active,
  handleSectionVisibilityChange,
}: Props): React.JSX.Element {
  const { data: registrationData, loading: loadingData } =
    useGetRegistrationsQuery()
  const registrations = registrationData?.getRegistrations
  const [registerOrganisation] = useRegisterOrganisationMutation({
    refetchQueries: [{ query: GetRegistrationsDocument }],
  })
  const [approveOrgRegistration] = useApproveOrgRegistrationMutation({
    refetchQueries: [{ query: GetRegistrationsDocument }],
  })

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  )
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
  const [rows, setRows] = React.useState<GridRowsProp<OrgRegistration>>([])
  const [updateOrgRegistrationApprovalCode] =
    useUpdateOrgRegistrationApprovalCodeMutation()
  const [registrationEmail, setRegistrationEmail] = useState('')

  useEffect(() => {
    setRows(registrations ?? [])
  }, [registrations])

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })
  }

  const processRowUpdate = async (newRow: GridRowModel<OrgRegistration>) => {
    setSnackbar(null)

    const result = await approveOrgRegistration({
      variables: {
        id: newRow.id,
        approved: newRow.approved,
      },
    })
    if (result.data) {
      setRegistrationEmail(result.data.approveOrgRegistration ?? '')
      return newRow
    }
    captureException(result.errors)
    throw result.errors
  }

  const handleProcessRowUpdateError = React.useCallback((err: Error) => {
    setSnackbar({
      children: `Error updating registration: ${err.message}`,
      severity: 'error',
    })
  }, [])

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    // do not get out of edit mode when clicking out of the cell
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleUpdateRegistrationCode = (reg: OrgRegistration) => async () => {
    try {
      const result = await updateOrgRegistrationApprovalCode({
        variables: {
          id: reg.id,
        },
      })
      if (result.data) {
        setRegistrationEmail(result.data.updateOrgRegistrationApprovalCode)
        return
      }
      captureException(result.errors)
      setSnackbar({
        children: `Error while updating the registration code: ${JSON.stringify(
          result.errors,
        )}`,
        severity: 'error',
      })
    } catch (err) {
      captureException(err)
      setSnackbar({
        children: `Error while updating the registration code: ${JSON.stringify(
          err,
        )}`,
        severity: 'error',
      })
    }
  }

  const columns: GridColDef[] = [
    { field: 'orgNumber', headerName: 'Organisation number', flex: 1 },
    { field: 'name', headerName: 'name', flex: 2 },
    { field: 'email', headerName: 'email', flex: 2 },
    { field: 'bookingSystem', headerName: 'booking System', flex: 1 },
    { field: 'nbEmployees', headerName: 'nb employees', flex: 1 },
    { field: 'industrialSectorId', headerName: 'SNI', flex: 1 },
    {
      field: 'approved',
      headerName: 'approved',
      flex: 1,
      editable: true,
      type: 'boolean',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={`save-${id}`}
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={`cancel-${id}`}
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ]
        }

        const actions = [
          <GridActionsCellItem
            key={`edit-${id}`}
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
        ]

        const reg = rows.find((r) => r.id === id)
        if (reg?.approved) {
          actions.push(
            <GridActionsCellItem
              key={`rsvp-${id}`}
              icon={
                <Tooltip title='Uppdatera registreringslänk'>
                  <RsvpIcon />
                </Tooltip>
              }
              label='invite'
              className='textPrimary'
              onClick={handleUpdateRegistrationCode(reg)}
              color='inherit'
              data-testid={`rsvp-button-${id}`}
            />,
          )
        }

        return actions
      },
    },
  ]

  const registerSchema = Yup.object().shape({
    orgNumber: Yup.string()
      .matches(
        /^[0-9]{6}-[0-9]{4}$/,
        'Det är inte ett giltigt organisationsnummer',
      )
      .required('Nödvändig'),
    email: Yup.string().email('Ogiltig e-postadress').required('Nödvändig'),
  })

  const registerOrgFormik = useFormik({
    initialValues: {
      orgNumber: '',
      email: '',
      acceptPolicies: true,
      bookingSystem: '',
    },
    validationSchema: registerSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        const result = await registerOrganisation({ variables: values })
        if (result.data?.registerOrganisation.errors) {
          setErrors(toErrorMap(result.data.registerOrganisation.errors))
        }
      } catch (err) {
        if (err instanceof ApolloError) {
          setErrors({ orgNumber: err.message })
        } else {
          setErrors({ orgNumber: 'something went wrong' })
        }
      }
    },
  })

  const handleCloseSnackbar = () => setSnackbar(null)

  return (
    <Section
      sectionId={SysadminSectionId.organisations}
      active={active}
      onVisibilityChange={handleSectionVisibilityChange}
      key='registrations-section'
    >
      <FlexingBox>
        <HeadRow>
          <TitleWithSub
            title='Registrations'
            sub=''
            infoDescription='list of registrations'
          />
        </HeadRow>
        <div style={{ padding: spacing.large }}>
          <DataGrid
            rows={rows ?? []}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            getRowId={(row) => row.id}
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            disableVirtualization
          />

          <form
            onSubmit={registerOrgFormik.handleSubmit}
            style={{ paddingTop: spacing.large }}
          >
            <Flex style={{ gap: spacing.medium }}>
              <TextField
                type='text'
                name='orgNumber'
                label='Organisationsnummer'
                onChange={registerOrgFormik.handleChange}
                onBlur={registerOrgFormik.handleBlur}
                value={registerOrgFormik.values.orgNumber}
                variant='standard'
                placeholder='xxxxxx-xxxx'
                slotProps={{ inputLabel: { shrink: true } }}
                error={
                  !!registerOrgFormik.touched.orgNumber &&
                  !!registerOrgFormik.errors.orgNumber
                }
                helperText={
                  registerOrgFormik.touched.orgNumber &&
                  registerOrgFormik.errors.orgNumber
                }
                required
              />
              <TextField
                type='text'
                name='email'
                label='Företagsmailadress'
                onChange={registerOrgFormik.handleChange}
                onBlur={registerOrgFormik.handleBlur}
                value={registerOrgFormik.values.email}
                variant='standard'
                placeholder='namn@domän.se'
                slotProps={{ inputLabel: { shrink: true } }}
                error={
                  !!registerOrgFormik.touched.email &&
                  !!registerOrgFormik.errors.email
                }
                helperText={
                  registerOrgFormik.touched.email &&
                  registerOrgFormik.errors.email
                }
                required
              />
              <TextField
                type='text'
                name='bookingSystem'
                label='bokningssystem'
                onChange={registerOrgFormik.handleChange}
                onBlur={registerOrgFormik.handleBlur}
                value={registerOrgFormik.values.bookingSystem}
                variant='standard'
                placeholder='fortnox'
                slotProps={{ inputLabel: { shrink: true } }}
                error={
                  !!registerOrgFormik.touched.bookingSystem &&
                  !!registerOrgFormik.errors.bookingSystem
                }
                helperText={
                  registerOrgFormik.touched.bookingSystem &&
                  registerOrgFormik.errors.bookingSystem
                }
                required
              />
              <ButtonGreen
                type='submit'
                disabled={
                  loadingData ||
                  !(
                    registerOrgFormik.values.orgNumber &&
                    registerOrgFormik.values.email
                  )
                }
              >
                Add registration
              </ButtonGreen>
            </Flex>
          </form>
        </div>
      </FlexingBox>
      {!!snackbar && (
        <SnackAlert open onClose={handleCloseSnackbar} {...snackbar} />
      )}
      <LinkSentPopup
        setRegistrationEmail={setRegistrationEmail}
        registrationEmail={registrationEmail}
      />
    </Section>
  )
}
