import React, { useCallback, useEffect, useState } from 'react'
import { ApolloError } from '@apollo/client'
import { v4 } from 'uuid'
import dayjs from 'dayjs'
import Svalna from '../@types'
import {
  AccountInfo,
  CategoryFragment,
  NumberSearchType,
  SearchField,
  StringSearchType,
  SupplierInfo,
  TransactionSearchCriterion,
  TransactionWithAttachedRule,
  useContainsNotRecatableLazyQuery,
  useContainsRuleOverlapLazyQuery,
  useGetAccountsLazyQuery,
  useGetFirstLastDateLazyQuery,
  useGetSuppliersLazyQuery,
  useGetTotalKrLazyQuery,
  useGetTransactionsCategoriesLazyQuery,
  useHasTransactionsQuery,
  useSearchTransactionsLazyQuery,
} from '../graphql/generated'

export type Pagination = {
  page: number
  pageSize: number
}

export type RecatContextType = {
  searchCriteria: TransactionSearchCriterion[]
  updateSearchCriteria: (searchCriteria: TransactionSearchCriterion) => void
  setSearchCriteria: React.Dispatch<
    React.SetStateAction<TransactionSearchCriterion[]>
  >
  addSearchCriterion: () => void
  removeSearchCriterion: (toRemove: TransactionSearchCriterion) => void
  resetSearch: () => void
  search: () => void
  searchWithCriteria: (criteria: TransactionSearchCriterion[]) => void
  transactions: TransactionWithAttachedRule[]
  numTransactions: number
  pagination: Pagination
  setPagination: (pagination: Pagination) => void
  loading: boolean
  transactionsError?: ApolloError | string
  ruleSuppliers: SupplierInfo[]
  ruleAccounts: AccountInfo[]
  affectedSuppliers: SupplierInfo[]
  affectedAccounts: AccountInfo[]
  transactionsCategories: CategoryFragment[]
  containsNotRecatable: boolean
  hasTransactions: boolean
  validationError: string
  firstTransactionDate: Date
  lastTransactionDate: Date
  totalKr: number
  containsRuleOverLap: boolean
  getFieldCriterion: (
    field: SearchField,
  ) => TransactionSearchCriterion | undefined
  getAvaillableSearchFields: () => SearchField[]
}

const initialValues: RecatContextType = {
  searchCriteria: [],
  updateSearchCriteria: () => {},
  setSearchCriteria: () => {},
  addSearchCriterion: () => {},
  removeSearchCriterion: () => {},
  resetSearch: () => {},
  search: () => {},
  searchWithCriteria: () => {},
  transactions: [],
  numTransactions: 0,
  pagination: { page: 0, pageSize: 20 },
  setPagination: () => {},
  loading: false,
  ruleSuppliers: [],
  ruleAccounts: [],
  affectedSuppliers: [],
  affectedAccounts: [],
  transactionsCategories: [],
  containsNotRecatable: false,
  hasTransactions: false,
  validationError: '',
  firstTransactionDate: dayjs.utc().toDate(),
  lastTransactionDate: dayjs.utc().toDate(),
  totalKr: 0,
  containsRuleOverLap: false,
  getFieldCriterion: () => undefined,
  getAvaillableSearchFields: () => [],
}

export const RecatContext = React.createContext<RecatContextType>(initialValues)

const { Provider } = RecatContext
export function RecatProvider({
  children,
}: Svalna.PropWithChildren): React.JSX.Element {
  const [searchCriteria, setSearchCriteria] = useState<
    TransactionSearchCriterion[]
  >([{ searchField: SearchField.SupplierName, id: v4() }])
  const updateSearchCriteria = useCallback(
    (updatedCriterion: TransactionSearchCriterion) => {
      setSearchCriteria((table) => {
        return table.map((criterion) => {
          if (criterion.id === updatedCriterion.id) {
            return updatedCriterion
          }
          return criterion
        })
      })
    },
    [],
  )
  const [searchTransac, transactionsData] = useSearchTransactionsLazyQuery()
  const [transactions, setTransactions] = useState<
    TransactionWithAttachedRule[]
  >([])
  const [ruleAccounts, setRuleAccounts] = useState<AccountInfo[]>([])
  const [ruleSuppliers, setRuleSuppliers] = useState<SupplierInfo[]>([])
  const [affectedAccounts, setAffectedAccounts] = useState<AccountInfo[]>([])
  const [affectedSuppliers, setAffectedSuppliers] = useState<SupplierInfo[]>([])
  const [transactionsCategories, setTransactionsCategories] = useState<
    CategoryFragment[]
  >([])
  const [getSuppliersQuery] = useGetSuppliersLazyQuery()
  const [getAccountIdsQuery] = useGetAccountsLazyQuery()
  const [getTransactionsCategories] = useGetTransactionsCategoriesLazyQuery()
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [containsNotRecatable, containsNotRecatableData] =
    useContainsNotRecatableLazyQuery()
  const [validationError, setValidationError] = useState('')
  const [firstTransactionDate, setFirtTransactionDate] = useState(
    dayjs.utc().toDate(),
  )
  const [lastTransactionDate, setLastTransactionDate] = useState(
    dayjs.utc().toDate(),
  )
  const [getFirstLastDateQuery] = useGetFirstLastDateLazyQuery()
  const [totalKr, setTotalKr] = useState(0)
  const [getTotalKrQuery] = useGetTotalKrLazyQuery()
  const [containsRuleOverLap, containsRuleOverLapData] =
    useContainsRuleOverlapLazyQuery()

  useEffect(() => {
    setTransactions(
      (transactionsData?.data?.searchTransactions
        .transactions as TransactionWithAttachedRule[]) ?? [],
    )
  }, [transactionsData?.data?.searchTransactions.transactions])

  /**
   * Returns a list of search fields for which we don't have a search criterion yet
   */
  const getAvaillableSearchFields = useCallback(() => {
    const searchFields = Object.values(SearchField)
    return searchFields.filter(
      (field) =>
        !searchCriteria.find((criterion) => criterion.searchField === field),
    )
  }, [searchCriteria])

  const addSearchCriterion = useCallback(() => {
    setSearchCriteria((table) => {
      // we need to crate a new table to trigger react to rerender. It is why we do not use push
      const result = [
        ...table,
        { searchField: getAvaillableSearchFields()[0], id: v4() },
      ]
      return result
    })
  }, [getAvaillableSearchFields])

  const removeSearchCriterion = useCallback(
    (toRemove: TransactionSearchCriterion) => {
      setSearchCriteria((table) => {
        return [...table.filter((criterion) => toRemove.id !== criterion.id)]
      })
    },
    [],
  )

  const resetSearch = () => {
    setSearchCriteria([{ searchField: SearchField.SupplierName, id: v4() }])
    setTransactions([])
  }

  const { data: hasTransactionsData } = useHasTransactionsQuery()

  const getSuppliers = useCallback(
    async (criteria: TransactionSearchCriterion[]) => {
      const suppliersInfo = await getSuppliersQuery({
        variables: {
          searchCriteria: criteria,
        },
      })

      if (suppliersInfo.error) {
        throw new Error(
          'Kunde inte hämta listan av leverantörer som matchar din sökning',
        )
      }
      if (suppliersInfo.data?.getSuppliers) {
        setAffectedSuppliers(suppliersInfo.data?.getSuppliers)
        if (
          criteria.find((criterion) =>
            [SearchField.SupplierName, SearchField.SupplierId].includes(
              criterion.searchField,
            ),
          )
        ) {
          setRuleSuppliers(suppliersInfo.data?.getSuppliers)
        } else {
          setRuleSuppliers([])
        }
      }
    },
    [getSuppliersQuery],
  )

  const getAccounts = useCallback(
    async (criteria: TransactionSearchCriterion[]) => {
      const accountIds = await getAccountIdsQuery({
        variables: {
          searchCriteria: criteria,
        },
      })
      if (accountIds.error) {
        throw new Error(
          'Kunde inte hämta listan av konton som matchar din sökning',
        )
      }
      if (accountIds.data?.getAccounts) {
        setAffectedAccounts(accountIds.data.getAccounts)
        /* if the user searched for account starting with a number the rule
         * should be writen as just this number, so no need to search for all the
         * matching accounts
         */
        const accountNameSearch = criteria.find(
          (criterion) => criterion.searchField === SearchField.AccountName,
        )
        const accountIdSearches = criteria.filter(
          (criterion) => criterion.searchField === SearchField.AccountId,
        )
        if (
          accountNameSearch ||
          (accountIdSearches.length &&
            accountIdSearches.find(
              (criterion) =>
                criterion.stringSearchType !== StringSearchType.StartWith,
            ))
        ) {
          setRuleAccounts(accountIds.data.getAccounts)
        } else {
          setRuleAccounts([])
        }
      }
    },
    [getAccountIdsQuery],
  )

  const getCategories = useCallback(
    async (criteria: TransactionSearchCriterion[]) => {
      const categories = await getTransactionsCategories({
        variables: {
          searchCriteria: criteria,
        },
      })
      if (categories.error) {
        throw new Error(
          'Kunde inte hämta listan av kategorier som matchar din sökning',
        )
      }
      if (categories.data?.getTransactionsCategories) {
        setTransactionsCategories(categories.data.getTransactionsCategories)
      }
    },
    [getTransactionsCategories],
  )

  const getFirstLastDate = useCallback(
    async (criteria: TransactionSearchCriterion[]) => {
      const firstLastDate = await getFirstLastDateQuery({
        variables: {
          searchCriteria: criteria,
        },
      })
      if (firstLastDate.error) {
        throw new Error(
          'Kunde inte hämta information om transaktionen som matchar din sökning',
        )
      }
      if (firstLastDate.data?.getFirstLastDate) {
        setFirtTransactionDate(
          dayjs.utc(firstLastDate.data?.getFirstLastDate.first).toDate(),
        )
        setLastTransactionDate(
          dayjs.utc(firstLastDate.data?.getFirstLastDate.last).toDate(),
        )
      }
    },
    [getFirstLastDateQuery],
  )

  const getTotalKr = useCallback(
    async (criteria: TransactionSearchCriterion[]) => {
      const totalKrResp = await getTotalKrQuery({
        variables: {
          searchCriteria: criteria,
        },
      })
      if (totalKrResp.error) {
        throw new Error(
          'Kunde inte hämta information om transaktionen som matchar din sökning',
        )
      }

      setTotalKr(totalKrResp.data?.getTotalKr ?? 0)
    },
    [getTotalKrQuery],
  )

  /*
   * Du to the pagination the search may not return the full
   * list of supplier ids and account ids. But this full list is needed
   * to create the recategorisation rules, so we search for them separately.
   * It could be an optimisation to do a the database request in the same
   * graphQL query, but I don' think it is worth it at this point.
   */
  const searchTransactionSummary = useCallback(
    async (criteria: TransactionSearchCriterion[]) => {
      const promises = []
      promises.push(getSuppliers(criteria))
      promises.push(getAccounts(criteria))
      promises.push(getCategories(criteria))
      promises.push(
        containsNotRecatable({
          variables: {
            searchCriteria: criteria,
          },
        }),
      )
      promises.push(
        containsRuleOverLap({
          variables: {
            searchCriteria: criteria,
          },
        }),
      )
      promises.push(getFirstLastDate(criteria))
      promises.push(getTotalKr(criteria))
      await Promise.all(promises)
    },
    [
      getSuppliers,
      getAccounts,
      getCategories,
      containsNotRecatable,
      containsRuleOverLap,
      getFirstLastDate,
      getTotalKr,
    ],
  )

  const searchWithCriteria = useCallback(
    async (
      criteria: TransactionSearchCriterion[],
      fetchSummary = true,
      page = 0,
      pageSize = 10,
    ) => {
      setLoading(true)
      setError('')
      try {
        await searchTransac({
          variables: {
            searchCriteria: criteria,
            page,
            pageSize,
          },
        })

        if (fetchSummary) {
          await searchTransactionSummary(criteria)
        }
      } catch (err) {
        if (
          typeof err === 'object' &&
          err !== null &&
          'message' in err &&
          typeof err.message === 'string'
        ) {
          setError(err.message)
        } else {
          throw err
        }
      }
      setLoading(false)
    },
    [searchTransac, searchTransactionSummary],
  )

  const search = useCallback(
    async (fetchSummary = true, page = 0, pageSize = 10) => {
      return searchWithCriteria(searchCriteria, fetchSummary, page, pageSize)
    },
    [searchWithCriteria, searchCriteria],
  )

  const setPag = async (p: Pagination) => {
    setPagination(p)
    await search(false, p.page, p.pageSize)
  }

  useEffect(() => {
    setValidationError('')
    if (
      searchCriteria
        .map((criterion) => criterion.searchField)
        .filter((item, index, array) => array.indexOf(item) !== index).length
    ) {
      setValidationError('Du kan bara ha ett sökkriterium per sökfält')
    }

    const dateSearcheCriteria = searchCriteria.filter(
      (criterion) => criterion.searchField === SearchField.Date,
    )

    dateSearcheCriteria.forEach((criterion) => {
      if (criterion.numberSearchType === NumberSearchType.Between) {
        if (!criterion.startDate !== !criterion.endDate) {
          setValidationError(
            'Du måste välja både ett startdatum och ett slutdatum',
          )
        }
      }
    })
  }, [searchCriteria])

  const getFieldCriterion = useCallback(
    (field: SearchField) => {
      return searchCriteria.find((criterion) => criterion.searchField === field)
    },
    [searchCriteria],
  )

  // transactionsData?.data?.searchTransactions.numRows get undefined when we load the next page of data
  // Data grid does not like it and reset the pagination to 0 if that happen (https://mui.com/x/react-data-grid/pagination/#index-based-pagination).
  // to avoid this problem we should keep the value of numTransactions until we get a new one
  //
  const rowCountRef = React.useRef(
    transactionsData?.data?.searchTransactions.numRows ?? 0,
  )

  const numTransactions = React.useMemo(() => {
    if (transactionsData?.data?.searchTransactions.numRows) {
      rowCountRef.current = transactionsData?.data?.searchTransactions.numRows
    }
    return rowCountRef.current
  }, [transactionsData?.data?.searchTransactions.numRows])

  return (
    <Provider
      value={{
        searchCriteria,
        updateSearchCriteria,
        setSearchCriteria,
        addSearchCriterion,
        removeSearchCriterion,
        resetSearch: resetSearch,
        search,
        searchWithCriteria,
        transactions,
        numTransactions,
        pagination,
        setPagination: setPag,
        loading,
        transactionsError: transactionsData.error ?? error,
        ruleAccounts,
        ruleSuppliers,
        affectedAccounts,
        affectedSuppliers,
        transactionsCategories: transactionsCategories,
        containsNotRecatable:
          !!containsNotRecatableData.data?.containsNotRecatable,
        hasTransactions: !!hasTransactionsData?.hasTransactions,
        validationError,
        firstTransactionDate,
        lastTransactionDate,
        totalKr,
        containsRuleOverLap:
          !!containsRuleOverLapData.data?.containsRuleOverlap,
        getFieldCriterion,
        getAvaillableSearchFields,
      }}
    >
      {children}
    </Provider>
  )
}
