import React, { useContext } from 'react'
import _ from 'lodash'
import { AppDataContext } from '../../../context/AppDataContext'
import { getNumberFormatter } from '../../../utils/adaptiveNumberFormat'
import { TooltipInfo } from '../../InfoIcon'
import { MaybeLoading } from '../../PageLoader'
import { H4Bold, S1, textCO2e } from '../../Typography'
import {
  GeneralSmallCardProps,
  noGoalDefinedErrorMessage,
  SmallCardBox,
  SmallCardContent,
  SmallCardFooter,
  SmallCardHeader,
  SmallCardTitle,
} from './SmallBudgetCard'
import { GoalContext } from '../../../context/GoalContext'
import { Loader } from '../../Loader'
import { spacing } from '../../../theme'

export function TotalBudgetCard({
  inlined,
}: GeneralSmallCardProps): React.JSX.Element {
  const { orgHasInitializedData } = useContext(AppDataContext)

  const { loading, error, data } = useContext(GoalContext)
  // General note: the `MaybeLoading` component takes `hasData`,
  // which means any derived data should be guaranteed to be
  // defined when it is actually rendered --
  // and the various defaults/null-coalsecing in the calculations
  // in this component should not actually end up used during render.
  const { goalEmissionCurve, referenceYear, targetYear } = data ?? {}
  const hasYears = _.isNumber(referenceYear) && _.isNumber(targetYear)
  const orgHasGoals = !!data?.curve.length
  const hasData = !loading && !error && !!data && orgHasGoals && hasYears

  const goalEmisByYear = goalEmissionCurve ?? []

  const goalBudget = _.sumBy(goalEmisByYear, (t) => t.y)

  const formatter = getNumberFormatter([goalBudget])
  return (
    <SmallCardBox inlined={inlined}>
      <SmallCardHeader inlined={inlined}>
        <SmallCardTitle>
          <span>Total budget</span>
          {loading ? (
            <Loader
              borderSize={2}
              size={14}
              style={{ display: 'inline-block', marginLeft: spacing.small }}
            />
          ) : (
            <span>
              {' '}
              {referenceYear}-{targetYear}
            </span>
          )}
        </SmallCardTitle>
        <TooltipInfo
          info={
            <>
              Anger den totala mängden utsläpp som är förenlig med
              organisationens mål, räknat från basår till målår med vald
              minskningstakt.
            </>
          }
        />
      </SmallCardHeader>
      <MaybeLoading
        loading={loading}
        hasData={hasData}
        defaultErrorMessage={
          !orgHasGoals
            ? noGoalDefinedErrorMessage
            : !hasYears
              ? 'Misslyckades att hämta måldata'
              : undefined
        }
        orgHasInitializedData={orgHasInitializedData}
      >
        <SmallCardContent>
          <H4Bold>{formatter.format(goalBudget)}</H4Bold>
          <S1>{textCO2e}</S1>
        </SmallCardContent>
      </MaybeLoading>
      <SmallCardFooter />
    </SmallCardBox>
  )
}
