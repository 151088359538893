import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import styled from 'styled-components'
import { colors } from '../theme'
import { AlertIcon } from './alert'
import alertCircleOutlineSvg from '../assets/icons/alert-circle-outline.svg'

const ButtonLighBorderStyle = styled(Button)`
  &:disabled {
    color: ${colors.gray};
    border-color: ${colors.gray};
  }
` as typeof Button // preserve generic types from Button

export function ButtonLightBorder<
  C extends React.ElementType = 'button',
  P extends {} = {},
>({ children, ...props }: ButtonProps<C, P>): React.JSX.Element {
  return (
    <ButtonLighBorderStyle {...props} variant='outlined' color='secondary'>
      {children}
    </ButtonLighBorderStyle>
  )
}

export function ButtonWhiteBorder<
  C extends React.ElementType = 'button',
  P extends {} = {},
>({ children, ...props }: ButtonProps<C, P>): React.JSX.Element {
  return (
    <ButtonLighBorderStyle {...props} variant='outlined' color='white'>
      {children}
    </ButtonLighBorderStyle>
  )
}

export const ButtonSelector = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{
  isActive?: boolean
  // the vscode-stuled-components extension needs a space here
  // eslint-disable-next-line prettier/prettier
}> `
  border: 1px solid #bbbbbb;
  border-radius: 10px;
  min-height: 33px;
  max-height: 33px;
  font-size: 14px;
  text-transform: none;
  color: ${colors.black};
  background-color: ${colors.white};
  ${(props) => props.isActive && `border: 2px solid ${colors.black};`}
  &:hover {
    border: 1px solid ${colors.black};
  }
`

export function ButtonGreenBorder<
  C extends React.ElementType = 'button',
  P extends {} = {},
>({ children, ...props }: ButtonProps<C, P>): React.JSX.Element {
  return (
    <ButtonLighBorderStyle {...props} variant='outlined'>
      {children}
    </ButtonLighBorderStyle>
  )
}

export function ButtonGreen<
  C extends React.ElementType = 'button',
  P extends {} = {},
>({ children, ...props }: ButtonProps<C, P>): React.JSX.Element {
  return (
    <Button {...props} variant='contained'>
      {children}
    </Button>
  )
}

export function ButtonBlack<
  C extends React.ElementType = 'button',
  P extends {} = {},
>({ children, ...props }: ButtonProps<C, P>): React.JSX.Element {
  return (
    <Button {...props} variant='contained' color='secondary'>
      {children}
    </Button>
  )
}

export const ErrorButtonBase = styled(Button)`
  background-color: ${colors.red};
  border-radius: 10px;
  text-transform: none;
` as typeof Button

export function ErrorButton<
  C extends React.ElementType = 'button',
  P extends {} = {},
>({ children, ...btnProps }: ButtonProps<C, P>): React.JSX.Element {
  return (
    <ErrorButtonBase
      startIcon={<AlertIcon src={alertCircleOutlineSvg} />}
      {...btnProps}
      variant='contained'
    >
      {children}
    </ErrorButtonBase>
  )
}

const StyledTableOfContentButton = styled(Button)`
  text-align: left;
  text-transform: none;
  padding-left: 0;
  font-size: 0.75rem;
` as typeof Button

export function TableOfContentButton<
  C extends React.ElementType = 'button',
  P extends { isActive: boolean } = { isActive: boolean },
>({ children, isActive, ...props }: ButtonProps<C, P>): React.JSX.Element {
  return (
    <StyledTableOfContentButton
      {...props}
      color='secondary'
      style={{
        textDecoration: isActive ? 'underline' : undefined,
        fontWeight: isActive ? 'bold' : undefined,
      }}
    >
      {children}
    </StyledTableOfContentButton>
  )
}

export const UnderlinedTextButton = styled(Button)`
  text-decoration: underline;
  text-transform: none;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
` as typeof Button
