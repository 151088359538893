import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex } from '../../../components/Flex'
import { ButtonGreen } from '../../../components/Buttons'
import { Body1, H5, S2Bold } from '../../../components/Typography'
import { spacing } from '../../../theme'
import {
  useRenewOrgRegistrationLinkMutation,
  useRenewUserRegistrationLinkMutation,
} from '../../../graphql/generated'
import { SnackAlert, SnackAlertProps } from '../../../components/SnackAlert'
import {
  FortnoxErrorType,
  FortnoxLoginContext,
} from '../fortnox/FortnoxLoginContext'

function MissingLicenseMsg() {
  return (
    <Flex column style={{ gap: spacing.xxxl }}>
      <S2Bold>Inloggning misslyckades</S2Bold>
      <H5 data-testid='missing-license-text'>
        Ert företag saknar licens att integrera Svalnas tjänster med Fortnox.
      </H5>
      <Flex column style={{ gap: spacing.medium }}>
        <Body1>
          Vi kunde tyvärr inte logga in dig eftersom ert företag saknar en
          licens från Fortnox för att kunna integrera applikationer, vilken
          krävs för att ni ska kunna dela er data med Svalnas tjänster.
        </Body1>
        <Body1>
          <span>
            För mer information om hur ni kan skaffa denna licens, se vår
          </span>{' '}
          <a href='https://svalna.gitlab.io/business-intelligence-frontend/permissions#Integrationslicensen'>
            dokumentation
          </a>
        </Body1>
      </Flex>
      <ButtonGreen
        href='https://svalna.se/web/sv/about#contact'
        style={{ width: '100%' }}
      >
        Kontakta oss
      </ButtonGreen>
    </Flex>
  )
}

function OrgNotRegisteredMsg() {
  const history = useHistory()
  return (
    <Flex column style={{ gap: spacing.xxxl }}>
      <S2Bold>Inloggning misslyckades</S2Bold>
      <H5 data-testid='not-registered-msg'>
        Företaget har inte ett konto hos Svalna än.
      </H5>
      <Body1>
        Du kunde inte loggas in för att företaget saknar konto hos Svalna. För
        att skaffa ett konto måste du eller någon annan på din organisation
        först skicka en förfrågan om konto till oss.{' '}
      </Body1>
      <ButtonGreen
        onClick={() => history.push('/registration')}
        style={{ width: '100%' }}
      >
        Starta förfrågan om konto
      </ButtonGreen>
    </Flex>
  )
}

function NotApprovedMsg() {
  return (
    <Flex column style={{ gap: spacing.xxxl }}>
      <S2Bold>Inloggning misslyckades</S2Bold>
      <H5 data-testid='missing-license-text'>
        Ert företags registrering är inte godkänd ännu.
      </H5>
      <Flex column style={{ gap: spacing.medium }}>
        <Body1>
          Du har begärt att bli registrerad hos Svalna men din anmälan har inte
          godkänts ännu. Vi kontaktar dig när godkännandet är klart.
        </Body1>
      </Flex>
      <ButtonGreen
        href='https://svalna.se/web/sv/about#contact'
        style={{ width: '100%' }}
      >
        Kontakta oss
      </ButtonGreen>
    </Flex>
  )
}

function UserNotRegisteredMsg() {
  return (
    <Flex column style={{ gap: spacing.xxxl }}>
      <S2Bold>Inloggning misslyckades</S2Bold>
      <H5 data-testid='not-registered-msg'>
        Användaren är inte registrerad hos Svalna.
      </H5>
      <Body1>
        Du kunde inte loggas in för att du inte är registrerad på ditt
        organisationskonto. För att kunna logga in måste administratören av din
        organisation i Svalna registrera dig. Om hen redan har gjort det, borde
        hen ha skickat en registreringslänk till dig. Se till att använda denna
        länk första gången du loggar in.
      </Body1>
    </Flex>
  )
}

function RegistrationCodeExpiredMsg(): React.JSX.Element {
  const [renewOrgRegistrationLink] = useRenewOrgRegistrationLinkMutation()
  const [renewUserRegistrationLink] = useRenewUserRegistrationLinkMutation()
  const [snackbar, setSnackbar] = useState<SnackAlertProps>({})

  const { userRegistrationCode, orgRegistrationCode } =
    useContext(FortnoxLoginContext)

  const renewLink = async () => {
    if (userRegistrationCode) {
      await renewUserRegistrationLink({
        variables: { registrationCode: userRegistrationCode },
      })
    } else {
      await renewOrgRegistrationLink({
        variables: { registrationCode: orgRegistrationCode ?? '' },
      })
    }
    setSnackbar({
      open: true,
      severity: 'success',
      children:
        'Ett meddelande har skickats till din e-post om adressen finns registrerad hos oss.',
      onClose: () => setSnackbar({}),
    })
  }
  return (
    <Flex column style={{ gap: spacing.xxxl }}>
      <SnackAlert {...snackbar} />
      <S2Bold>Inloggning misslyckades</S2Bold>
      <H5 data-testid='not-registered-msg'>
        Registreringslänken har upphört att gälla.
      </H5>
      <Body1>Registreringslänken du använder har upphört att gälla.</Body1>
      <ButtonGreen onClick={renewLink} data-testid='renew-button'>
        Skicka ny länk
      </ButtonGreen>
    </Flex>
  )
}

function RegistrationCodeNotFoundMsg() {
  return (
    <Flex column style={{ gap: spacing.xxxl }}>
      <S2Bold>Inloggning misslyckades</S2Bold>
      <H5 data-testid='not-registered-msg'>
        Registreringslänken är inte giltig
      </H5>
      <Body1>
        Registreringslänken du använder är inte giltig. Be administratören att
        skapa en ny länk och skicka den till dig.
      </Body1>
    </Flex>
  )
}

function RegistrationCodeMissingMsg() {
  return (
    <Flex column style={{ gap: spacing.xxxl }}>
      <S2Bold>Inloggning misslyckades</S2Bold>
      <H5 data-testid='not-registered-msg'>
        Använd registreringskoden för att logga in för första gången
      </H5>
      <Body1>
        Om din organisation är registrerad och du loggar in för första gången
        borde du ha fått ett mejl med en länk. Använd denna länk för att Skapa
        ditt konto. Om din organisation inte är registrerad, vänligen registrera
        din organisation och vänta på att registreringen blir validerad.
      </Body1>
    </Flex>
  )
}

export function FortnoxErrorMsg({
  errorType,
}: {
  errorType: FortnoxErrorType
}): React.JSX.Element | null {
  switch (errorType) {
    case FortnoxErrorType.MissingLicense:
      return <MissingLicenseMsg />
    case FortnoxErrorType.OrgNotRegistered:
      return <OrgNotRegisteredMsg />
    case FortnoxErrorType.UserNotRegistered:
      return <UserNotRegisteredMsg />
    case FortnoxErrorType.NotApproved:
      return <NotApprovedMsg />
    case FortnoxErrorType.RegistrationCodeExpired:
      return <RegistrationCodeExpiredMsg />
    case FortnoxErrorType.RegistrationCodeNotFound:
      return <RegistrationCodeNotFoundMsg />
    case FortnoxErrorType.RegistrationCodeMissing:
      return <RegistrationCodeMissingMsg />
    default:
      return null
  }
}
