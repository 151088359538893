import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.scss'
import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn: 'https://0b81714eefe546da9750cb1d522cfb3d@o261806.ingest.sentry.io/5966421',
  integrations: [Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
