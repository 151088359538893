import React, { useMemo } from 'react'
import { ApolloError } from '@apollo/client'
import _ from 'lodash'
import dayjs from 'dayjs'
import {
  FilterInput,
  GetOrgGoalQuery,
  useGetEmissionRangeQuery,
  useGetOrgGoalQuery,
} from '../graphql/generated'
import { OpenEndedRange } from '../utils/sequence'
import { useFilterInput } from '../hooks/useFilterInput'
import { useInterpolatedEmissions } from '../components/goal/useInterpolatedEmissions'
import { usePartialYearEmission } from '../hooks/usePartialYearEmission'

/**
 * General data point type for goal-related use;
 * x is often a year, y is often co2e or a factor referring to a reduction in co2e.
 */
export type DataPoint = { x: number; y: number }

/**
 * Reduction factors for a given year relative the reference year's emissions.
 */
export type GoalReductionFactors = {
  /** Target (i.e. goal) factor for the year, relative the reference yaer's emissions. */
  currentTargetReductionFactor: number
  /** Actual factor for the current year's emissions relative the reference year's emissions. */
  currentReductionFactor: number
}

// Convenience GQL goal data types, sans __typename and undefined,
// and omitting `curve` (see explanation in GoalData below).
type GQLGoalData = Omit<
  Omit<NonNullable<GetOrgGoalQuery['getOrgGoal']>, '__typename'>,
  'curve'
>

/**
 * Organisation goal data.
 *
 * This contains data loaded from the goal GQL endpoint,
 * as well as derived values related to goals.
 */
export type GoalData = GQLGoalData & {
  /**
   * Goal curve as factors: { x: year, y: ratio of emissions relative the reference year }.
   *
   * Note: this field replaces the GQL `curve` type for convenience, as
   * GQL does not return the curve in the `DataPoint[]` form.
   */
  curve: DataPoint[]
  /** Goal curve as { x: year, y: co2e }. */
  goalEmissionCurve: DataPoint[]
  /**
   * Interpolated emissions as { x: year, y: co2e }. If there were gap years in the emission
   * data, they have been interpolated linearly, so that there are emissions for every year
   * between the first and last emissions.
   * Note: This never contains the partial year.
   * */
  interpolatedEmissions: DataPoint[]
  /** Year ranges for which emissions were originally defined. */
  emissionYearRanges: OpenEndedRange[]
  /**
   * Gap year ranges for which no emissions were originally defined.
   * These ranges contain interpolated data in `interpolatedEmissions`.
   * */
  emissionYearGaps: OpenEndedRange[]
  /** Emission value at the reference year. */
  referenceEmission: number
  /** Goal emission value for the target year. */
  targetEmission: number
  /**
   * Reduction factors (ratios of the reference emission) for the current year:
   * factor computed for the actual emissions, as well as the goal factor.
   * Note: This never refers to the partial year.
   */
  reductionFactors: GoalReductionFactors
  /** First year for which the organisation has emission data. */
  firstOrgDataYear: number
  /** Last year for which the organisation has emission data. */
  lastOrgDataYear: number
  /** Emissions for the partial year, if there is one. */
  partialYearEmission: DataPoint | undefined
  /** Partial year reduction factor compared to goal for that year, if it exists */
  partialYearReductionFactors: GoalReductionFactors | undefined
}

/**
 * The goal context data represents all goal-related data for the current organisation.
 *
 * - If the data is still loading, `loading` should be true.
 * - If there was an error fetching the data, `error` should be set.
 * - If the organisation does not have a goal, `data` should be `undefined`.
 * - If the org has a goal, `data` should be a complete {@link GoalData} object.
 */
export type GoalContextData =
  | {
      loading: true
      // using `never` to help avoid unintentional assignment for the loading case.
      error?: never
      data?: never
    }
  | {
      loading: false
      error: Error | ApolloError | undefined
      data: GoalData | undefined // `undefined` = no goal set, or an error occured.
    }

export const GoalContext = React.createContext<GoalContextData>({
  loading: true,
})

export function GoalContextProvider({
  children,
}: {
  children: React.ReactNode
}): React.JSX.Element {
  const {
    data: goalData,
    loading: goalLoading,
    error: goalError,
  } = useGetOrgGoalQuery()

  const { orgUnitId } = useFilterInput()
  const filter: FilterInput = {
    // Goals are not defined in terms of annual work force,
    // and it is a required field, so set it to false:
    useAnnualWorkForce: false,
    // Use org unit from global filter:
    orgUnitId,
    // No other fields make sense for the goal,
    // as the goal definition has no relation to those variables.
  }

  // This uses `useGetTrendQuery` internally,
  // which never includes the partial year.
  const {
    interpolatedEmissions,
    emissionRanges,
    emissonGaps,
    loading: trendLoading,
    error: trendError,
  } = useInterpolatedEmissions(filter)

  // Fetch the partial year emissions if there is such a year.
  // Again: the partial year is never in the `useGetTrendQuery` result.
  const {
    partialYearEmission,
    loading: partialYearEmisLoading,
    error: partialYearEmisError,
  } = usePartialYearEmission({ filter })

  // Org-wide emission ranges (first/last year)
  const {
    data: orgEmisRangeData,
    loading: orgEmisRangeLoading,
    error: orgEmisRangeError,
  } = useGetEmissionRangeQuery()

  const goalContextData = useMemo<GoalContextData>(() => {
    if (
      goalLoading ||
      trendLoading ||
      orgEmisRangeLoading ||
      partialYearEmisLoading
    ) {
      return {
        loading: true,
      }
    }
    // Everything should be loaded:
    // If there is no goal data, and no errors occured, return "no goal".
    if (
      !goalData?.getOrgGoal &&
      !goalError &&
      !trendError &&
      !orgEmisRangeError &&
      !partialYearEmisError
    ) {
      return {
        loading: false,
        data: undefined,
      }
    }

    // There is goal data for this organisation:
    // If any data is missing, the `data` field should be returned as undefined.
    // Any errors should also be returned in `error`, independently.

    // Get years from ISO date string (the string value is verified later in this useMemo):
    const firstOrgDataYear = dayjs
      .utc(orgEmisRangeData?.getEmissionRange.from ?? '')
      .year()
    const lastOrgDataYear = dayjs
      .utc(orgEmisRangeData?.getEmissionRange.to ?? '')
      .year()

    const refEmission = interpolatedEmissions.find(
      (p) => p.x === goalData?.getOrgGoal?.referenceYear,
    )?.y

    const targetEmission =
      goalData?.getOrgGoal &&
      _.isNumber(refEmission) &&
      _.isNumber(goalData.getOrgGoal.targetFactor) &&
      refEmission * goalData.getOrgGoal.targetFactor

    // This is dependent on possibly missing values,
    // but these are all validated later in this useMemo:
    const goalFactors = (goalData?.getOrgGoal?.curve ?? []).map((p) => ({
      x: p.year,
      y: p.targetFactorRelReferenceYear,
    }))

    // Same thing, this is validated later.
    const reductionFactors = getReductionFactors({
      emissions: interpolatedEmissions,
      goalFactors,
      referenceEmission: refEmission ?? 0,
    })

    // Get reduction factors for the partial year,
    // undefined is expected if no such year exists.
    // Same thing, this is validated later.
    const partialYearReductionFactors: GoalReductionFactors | undefined =
      partialYearEmission &&
      getReductionFactors({
        emissions: [partialYearEmission],
        goalFactors,
        referenceEmission: refEmission ?? 0,
      })

    // A goal exists -- if any of the required values are missing, return an error state.
    if (
      !goalData?.getOrgGoal ||
      !_.isNumber(refEmission) ||
      !_.isNumber(targetEmission) ||
      !reductionFactors ||
      !_.isNumber(reductionFactors.currentReductionFactor) ||
      !orgEmisRangeData?.getEmissionRange.from ||
      !orgEmisRangeData?.getEmissionRange.to ||
      !goalFactors.length
    ) {
      return {
        loading: false,
        error:
          goalError ||
          trendError ||
          orgEmisRangeError ||
          partialYearEmisError ||
          new Error('Något gick fel'),
        data: undefined,
      }
    }

    // All values valid, return goal data:
    return {
      loading: false,
      error:
        goalError || trendError || orgEmisRangeError || partialYearEmisError,
      data: {
        ...goalData.getOrgGoal,
        curve: goalFactors,
        goalEmissionCurve: goalFactors.map((p) => ({
          x: p.x,
          y: p.y * refEmission,
        })),
        interpolatedEmissions,
        emissionYearRanges: emissionRanges,
        emissionYearGaps: emissonGaps,
        referenceEmission: refEmission,
        targetEmission: targetEmission,
        reductionFactors,
        firstOrgDataYear,
        lastOrgDataYear,
        partialYearEmission,
        partialYearReductionFactors,
      },
    }
  }, [
    goalLoading,
    trendLoading,
    orgEmisRangeLoading,
    partialYearEmisLoading,
    orgEmisRangeData?.getEmissionRange.from,
    orgEmisRangeData?.getEmissionRange.to,
    interpolatedEmissions,
    goalData?.getOrgGoal,
    emissionRanges,
    emissonGaps,
    partialYearEmission,
    goalError,
    trendError,
    orgEmisRangeError,
    partialYearEmisError,
  ])

  return (
    <GoalContext.Provider value={goalContextData}>
      {children}
    </GoalContext.Provider>
  )
}

/**
 * Compute the current and goal reduction factors relative the reference year's emissions.
 *
 * @returns The reduction factors if they could be computed, or undefined if required data was missing.
 */
export function getReductionFactors({
  emissions,
  referenceEmission,
  goalFactors,
}: {
  /** Emissions as CO2e over years. */
  emissions: DataPoint[]
  /** Goal curve over years, as reduction factor relative the reference year. */
  goalFactors: DataPoint[]
  /** Emission value in CO2e at the reference year. */
  referenceEmission: number
}): GoalReductionFactors | undefined {
  if (referenceEmission === 0) {
    return undefined
  }

  const lastYearPoint = _.maxBy(emissions, (p) => p.x)
  const lastEmission = lastYearPoint?.y
  if (!_.isNumber(lastEmission)) {
    return undefined
  }

  const curYearTargetFactor = goalFactors.find(
    (p) => p.x === lastYearPoint?.x,
  )?.y
  if (!_.isNumber(curYearTargetFactor)) {
    return undefined
  }

  return {
    currentReductionFactor: lastEmission / referenceEmission,
    currentTargetReductionFactor: curYearTargetFactor,
  }
}
