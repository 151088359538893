import React, { useContext, useEffect } from 'react'
import { Button } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { captureException } from '@sentry/browser'
import { LoginPageWrapper } from '../components/LoginPageWrapper'
import { Flex } from '../../../components/Flex'
import { Loader } from '../../../components/Loader'
import { FortnoxErrorMsg } from '../components/FortnoxErrorMsg'
import { Body1, H4Bold } from '../../../components/Typography'
import { FortnoxErrorType, FortnoxLoginContext } from './FortnoxLoginContext'
import { goToFortnox, parseFortnoxState } from '../../../utils/FortnoxUtils'
import { spacing } from '../../../theme'

export function FortnoxPage(): React.JSX.Element {
  const history = useHistory()
  const {
    setFortnoxErrorType,
    setError,
    setLoading,
    loading,
    error,
    fortnoxErrorType,
    setCode,
    setOrgRegistrationCode,
    setUserRegistrationCode,
  } = useContext(FortnoxLoginContext)
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const err = queryParams.get('error')
    const description = queryParams.get('error_description')
    if (err) {
      captureException(err)
      switch (err) {
        case 'error_missing_license':
        case 'error_missing_app_license':
          setFortnoxErrorType(FortnoxErrorType.MissingLicense)
          break
        default:
          setError(`Något gick fel: ${description}`)
      }
      setLoading(false)
      return
    }
    const stateString = queryParams.get('state')
    if (!stateString) {
      // this is not a redirect from Fortnox OAuth
      // check if we have a registrationCode
      const registrationCode = queryParams.get('registrationCode')
      const userCode = queryParams.get('userCode')
      let flag: 'u' | 'o' | undefined
      if (userCode) {
        flag = 'u'
      } else if (registrationCode) {
        flag = 'o'
      }
      // try to login with fortnox
      goToFortnox(userCode ?? registrationCode, flag)
      return
    }

    const { state, registrationCode, flag } = parseFortnoxState(stateString)
    if (state !== localStorage.getItem('fortnox_state')) {
      captureException(
        'the returned state does not match the state stored localy',
      )
      setError(
        'Det returnerade tillståndet matchar inte det skickade tillståndet. Försök igen.',
      )
      setLoading(false)
      return
    }

    const c = queryParams.get('code')
    if (c === null) {
      captureException('no code when getting answer from fortnox')
      setError('Ingen returnerad kod. Försök igen.')
      setLoading(false)
      return
    }
    if (registrationCode) {
      if (flag === 'u') {
        setUserRegistrationCode(registrationCode)
      } else {
        setOrgRegistrationCode(registrationCode)
      }
    }
    setCode(c)
  }, [
    history,
    location.search,
    setCode,
    setError,
    setFortnoxErrorType,
    setLoading,
    setOrgRegistrationCode,
    setUserRegistrationCode,
  ])

  return loading ? (
    <Flex
      column
      itemsCenter
      justifyCenter
      style={{ height: '100vh', gap: spacing.xl }}
      data-testid='loader'
    >
      <H4Bold>Carbon Intelligence system</H4Bold>
      <Loader size={80} borderSize={20} />
      <Body1>Du loggas in</Body1>
    </Flex>
  ) : (
    <LoginPageWrapper error={error}>
      <Flex
        column
        stretchWidth
        spaceBetween
        style={{
          height: '100%',
        }}
      >
        <FortnoxErrorMsg errorType={fortnoxErrorType} />
        <Button
          color='secondary'
          onClick={() => {
            history.push('/')
          }}
          style={{ alignSelf: 'center' }}
        >
          Tillbaka till startsidan
        </Button>
      </Flex>
    </LoginPageWrapper>
  )
}
